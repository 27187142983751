.duonutContainer {
  height: 100vh;
  position: relative;
  background-color: #F4F9FC;
  background-size: cover;
  color: black;
  overflow-y: auto;
}

.duonutContainerBlur {
  /* backdrop-filter: blur(15px);
  min-height: 100vh; */
  padding-top: 90px;
  padding-bottom: 10vh;
  width: 1300px;
  margin: 0 auto;
}

.heading {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 38px;
  max-width: 100%;
  text-align: center;
  color: black;
  padding-top: 90px;
  padding-bottom: 8px;
  cursor: default;
  pointer-events: none;
}

.myduonutDescription {
  font-family: Campton, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  text-align: center;
}

.main {
  margin-left: 50px;
  margin-top: 12px;
  position: relative;
}

.main .title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: black;
  cursor: default;
}

.duonuts {
  width: 100%;
  display: flex;
  margin-top: 16px;
  overflow: scroll;
  scroll-behavior: smooth;
  padding-left: 50px;
  /* flex-wrap: wrap; */
}

/* .duonuts::-webkit-scrollbar {
    display: none;
} */

.con1 {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.con1::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.duonuts2 {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  margin-left: 52px;
  /* margin-bottom: 3rem; */
}

.duonutContainer .load-more-btn {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.duonutContainer .load-more-btn button {
  font-size: 16px;
  line-height: 22px;
  color: #3184C9;
  border: 0;
  background-color: transparent;
  font-family: "Mulish";
  ;
  font-weight: 700;
  cursor: pointer;
}

.MD1home_page__btn_container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  margin-top: 132px;
  padding: 16px 0;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
}

.MD1start-creating-btn {
  padding: 10px 20px;
  /* font-family: Nunito_Sans_Semibold; */
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  margin: 0rem 0.5rem;
  border-radius: 0px;
  background-color: rgb(243, 243, 241);
  color: black;
  cursor: pointer;
}

.MD1start-creating-btn:hover {
  background-color: white;
  border: 2px solid black;
}

.MD1start-creating-btn-2 {
  padding: 10px 13px;
  /* font-family: Nunito_Sans_Semibold; */
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin: 0rem 0.5rem;
  border-radius: 0px;
  background-color: black;
  border: 2px solid black;
  color: white;
  cursor: pointer;
}

.MD1start-creating-btn-2:hover {
  background-color: white;
  color: black;
  border: 2px solid black;
}

.myDuonut__scroll_btn {
  align-items: center;
  border-radius: 100%;
  box-shadow: 1.6px 1.6px 3.2px rgba(0, 0, 0, .125);
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
  width: 40px;
  z-index: 1;
}

.myDuonut_scroll_onclick_left {
  left: 2px
}

.myDuonut_scroll_onclick_right {
  right: 10px;
}

@media only screen and (max-width: 1100px) {

  .duonutContainer .duonuts2,
  .duonutContainer .main,
  .duonutContainer .filterContainer {
    margin-left: 1.2rem;
  }
}

@media only screen and (max-width: 500px) {

  .duonutContainerBlur {
    width: 100%;
    margin: 0 auto;
  }

  .duonutContainer .filterContainer {
    flex-direction: column;
  }

  .duonutContainer .filterContainer .left {
    margin-bottom: 1rem;
  }

  .duonutContainer .filterContainer .sortby-text {
    margin-left: 4px;
  }

  .duonuts2 {
    justify-content: center;
  }

  .MD1home_page__btn_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center
  }
}