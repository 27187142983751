.PublishModalMain {
    text-align: center;
    margin-top: 26vh;
    border-radius: 2vh;
}

.PublishBtn {
    border: 1px solid black;
    border-radius: 5px;
    background-color: black;
    padding: 10px 16px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}

.PublishBtn:hover {
    background-color: white;
    border: 1px solid black;
    color: black;
}

.PublishBtn:disabled {
    cursor: wait;
    /* background: #3184C9b5; */
    background-image: linear-gradient(270deg, #686767, #050505b5) !important;
    /* background: linear-gradient(121.19deg, rgba(133, 239, 49, 0) 25.73%, hsla(0, 0%, 100%, 0.3) 45.27%, rgba(133, 239, 49, 0) 62.27%), #9149ec !important; */
    animation: publishing-animation 60s linear infinite;
}

@keyframes publishing-animation {
    0% {
        background-position: -5000px 0;
    }

    100% {
        background-position: 5000px 0;
    }
}

.publishContinuebtn {
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    padding: 12px 20px;
    border-radius: 5px;
    margin: 2vh;
    color: black;
    cursor: pointer;
}

.publishContinuebtn:hover {
    background-color: #0E2C45;
    border: 1px solid #0E2C45;
    color: white;
}

.customizeButton {
    border: 1px solid #0E2C45;
    border-radius: 5px;
    background-color: #0E2C45;
    padding: 12px 20px;
    margin: 2vh;
    color: white;
    cursor: pointer;
}

.customizeButton:hover {
    background-color: white;
    border: 1px solid #0E2C45;
    color: black;
}

.publish_dashboard_row_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 1px solid #ecebeb;
    border-radius: 10px;
    margin: 5px;
}

.publish_dashboard_row {
    display: flex;
    justify-content: center;
    align-items: center;
}


.roundCircle_success {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #0b996f;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
}

.roundCircle {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #cfcfcf;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
}

.publishCloseIcon {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.publish_dashboard_button {
    border: 1px solid #0E2C45;
    background-color: transparent;
    padding: 8px 15px;
    border-radius: 8px;
    color: #0E2C45;
    cursor: pointer;
    font-size: 17px;
}

.publish_dashboard_button:hover {
    /* -ms-transform: scale(1.03);
    /* IE 9 */
    /* -webkit-transform: scale(1.03); */
    /* Safari 3-8 */
    /* transform: scale(1.03); */
    background-color: #0E2C45;
    color: white;
}