/* Publish button disable  */
.btn-primary:disabled {
  cursor: wait;
  /* background: #3184C9b5; */
  background-image: linear-gradient(270deg, #686767, #050505b5) !important;
  /* background: linear-gradient(121.19deg, rgba(133, 239, 49, 0) 25.73%, hsla(0, 0%, 100%, 0.3) 45.27%, rgba(133, 239, 49, 0) 62.27%), #9149ec !important; */
  animation: publishing-animation 60s linear infinite;
}

@keyframes publishing-animation {
  0% {
    background-position: -5000px 0;
  }

  100% {
    background-position: 5000px 0;
  }
}

.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  background-color: #fff;
  /* z-index: var(--navbar-index); */
  z-index: 1000;
  /* NEW CHNAGE FOR POP_UP*/
}

.navbar__wrapper {
  padding: 13px 40px 13px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar_wrapper__hamberger_menu,
.navbar__user_icon {
  display: none;
}

.navbar-container__items {
  padding: 0 16px;
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
}

.navbar-container__items .navbar-item {
  padding: 10px 10px;
  margin: 0 5px;
  white-space: nowrap;
  font-size: 16px;
}

.navbar-container__items .navbar-item .select-icon {
  margin-left: 7px;
  margin-bottom: 2px;
}

.navbar-container__items .navbar-item:hover {
  /* background: rgba(146, 144, 154, 0.4); */
  font-weight: 600;
  cursor: pointer;
}

/* buttons */
.navbar-container__buttons {
  display: flex;
  align-items: center;
}

.navbar-container__buttons button {
  font-family: "Mulish";
  /* border: 2px solid black; */
}

.navbar-container .nav-button {
  line-height: 19px;
  vertical-align: middle;
  font-family: "Mulish";
  font-size: 14px;
  font-weight: 700;
}

.navbar-container__buttons .sign-in-btn {
  margin-right: 16px;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  transition: 0.1s;
  font-size: 16px;
  font-weight: 500;
}

.navbar-container__buttons .sign-in-btn:hover {
  /* background: rgba(146, 144, 154, 0.4); */
  /* border: 2px solid #3184C9; */
  font-weight: 600;
  color: black;
}

.navbar-container .navbar-bottom {
  display: none;
}

.navbar__wrapper .navbar__logo {
  /* cursor: pointer; */
}

.navbar__wrapper .input-box {
  display: none;
}

.navbar__wrapper .settings_title_button {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 0px;
  font-family: "Mulish";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  cursor: text;
  background-color: transparent;
}

.navbar__wrapper .settings_title_button .down-arrow {
  margin-left: 26.25px;
  margin-right: 18.25px;
}

#hidden {
  display: none;
}

.dot-menu {
  padding: 4px 8px;
}

.dot-menu-items {
  position: fixed;
  z-index: 2000;
  width: 200px;
  right: 1.75rem;
  background: #ffffff;
  box-shadow: 2px 4px 4px rgb(0 0 0 / 13%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dot-menu-item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  align-self: stretch;
  text-align: left;
  border: 0;
  background-color: transparent;
  position: relative;
  color: black;
}

/* loader */
.dot-menu-items .lds-ring {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% - 15px), calc(-50% - 5px));
}

.dot-menu-items .lds-ring div {
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-color: #626262 transparent transparent transparent;
}

.dot-menu-item.publish {
  border-bottom: 0.694444px solid rgba(0, 0, 0, 0.19);
}

.dot-menu-item:disabled {
  cursor: wait;
  background: #afafafb5;
}

.mobile-only {
  display: none;
}

.mobile-navbar {
  background-color: transparent;
  color: white;
}

.dropdown-nav {
  opacity: 0;
  transition: transform .3s cubic-bezier(0.88, -0.72, 0, 1), opacity .3s ease-in-out;
  /* transform: translateY(-4rem); */
  transform: translateX(-1.4rem);
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
  background: white;
  margin-top: 0.5rem;
  display: none;
  color: rgba(11, 35, 48, 0.8);
}

.navbar-item:hover .dropdown-nav {
  opacity: 1;
  display: block;
  /* transform: translateY(0); */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.dropdown-nav:hover {
  opacity: 1;
  display: block;
  /* transform: translateY(0); */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.dropdown-nav li {
  position: relative;
  z-index: 2;
  display: block;
  letter-spacing: 0.01rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1rem;
  transition: 0.2s;
  color: rgba(11, 35, 48, 0.8);
}

.dropdown-nav li:hover {
  color: black;
  background-color: rgb(243, 243, 241);
}

/* //hamburgermenu ////// */
.designpagehamburgermenu {
  width: 2.5rem;
  height: 2.5rem;
  -webkit-box-pack: center;
  justify-content: center;
  /* border-radius: 0.25rem; */
  transition: background-color 0.2s ease-out 0s;
  cursor: pointer;
  margin-left: 1rem;
}

.designpagehamburgermenu:hover {
  border: 2px solid #3184C9;
}

.hamburgermenuProfile {
  font-size: 2.2rem !important;
}

.hamburgermenuProfile:hover {
  font-size: 2.3rem !important;
  color: #3184C9;
}

.design-hamburgermenuProfile {
  font-size: 2.2rem !important;
  color: white;
}

.design-hamburgermenuProfile:hover {
  font-size: 2.3rem !important;
  color: white;
}

.hamburgermenu {
  position: absolute;
  inset: 0px 0px auto auto;
  width: 11rem;
  z-index: 2;
  transform: translate(-48px, 54px);
  display: none;
}

.hamburgermenuinside {
  background: rgb(255, 255, 255);
  border-radius: 0.5rem;
  overflow: hidden;
  pointer-events: auto;
  height: auto;
  width: 11rem;
  display: flex;
  flex-direction: column;
  transform: translateY(0px);
  opacity: 1;
  box-shadow: rgba(0, 15, 51, 0.15) 0px 0.75rem 2rem;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1.5) 0s;
  padding: 0.5rem;
}

.hamburgermenuitem {
  /* width: 100%; */
  background-color: rgb(255, 255, 255);
  color: black;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  /* justify-content: space-between; */
  height: 2rem;
  padding: 0px 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0s;
  cursor: pointer;
}

.hamburgermenuitem i {
  width: 1rem;
}

.hamburgermenuitem:hover {
  background-color: rgb(242, 244, 252);
  color: black;
}

.hamburgeropen {
  display: block;
}

/* ///////////////////////////////////// */
.design_page_nav_buttons {
  background: none;
  color: white;
  cursor: pointer;
  margin: 0px 0.6rem;
  font-size: 1rem;
  font-family: "Mulish";
  position: relative;
  padding: 0px 3px;
}

.design_page_nav_buttons-active {
  background: black;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin: 0px 1rem;
  font-size: 1rem;
  padding: 8px 12px;
  font-family: "Mulish";
}

.duonutProButton {
  cursor: pointer;
  padding: 8px;
  font-size: 14px;
  background-color: rgb(235, 236, 240, 0.2);
  border: none !important;
  border-radius: 5px;
  color: white;
}

.duonutProButton:hover {
  background-color: rgb(235, 236, 240, 0.5);
}

.undoButton:hover {
  cursor: pointer;
  /* background-color: rgb(235, 236, 240, 1) !important; */
}

@media only screen and (max-width: 1100px) {
  .navbar-container {
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #3184C9 -12.18%, #8f1b77 108.63%); */
    background-color: rgb(235, 236, 240);
    /* border-radius: 0px 0px 50px 50px; */
    box-shadow: none !important;
  }

  .navbar-container .logo-small {
    width: 80px;
  }

  .navbar-container .navbar__wrapper {
    padding: 20px 20px 15px 20px;
    flex-direction: column;
    align-items: stretch;
  }

  /* enable logo for design page */
  .navbar-container .navbar__logo {
    display: none;
  }

  .navbar__wrapper .input-box {
    display: block;
    width: auto;
    flex: 1;
    margin-left: 27px;
  }

  .navbar__user_icon {
    display: none;
  }

  /* SHORT NAVBAR FOR DESIGN PAGE */
  .navbar-container#navbar-short {
    border-radius: 0;
    padding-bottom: 0 !important;
  }

  .navbar__wrapper#navbar-short {
    height: 40px;
    overflow: hidden;
  }

  .navbar__wrapper__left {
    flex: 1;
    justify-content: space-between;
  }

  /* nav items */
  .navbar-container .navbar-container__items {
    display: none;
    padding: 0 10px;
  }

  .navbar-container__buttons {
    display: none;
  }

  .navbar_wrapper__hamberger_menu,
  .navbar_wrapper__user_icon {
    display: flex;
  }

  .navbar_wrapper__hamberger_menu#hidden,
  .navbar_wrapper__user_icon#hidden {
    display: none;
  }

  .home_icon {
    margin-right: 15px;
  }

  /* navbar bottom */
  .navbar__wrapper .navbar-bottom {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 17px;
    margin-bottom: 15px;
  }

  .navbar__wrapper .navbar-bottom p {
    font-size: 20px;
    line-height: 27px;
    font-family: Nunito_Sans_Bold;
    color: #ffffff;
    /* margin-right: 10px; */
  }

  .navbar-container.navbar-container-collapse {
    border-radius: 0;
    /* padding-bottom: 13px; */
  }

  .navbar-bottom {
    display: none !important;
  }

  .navbar-bottom#active {
    display: flex !important;
  }

  /* design page */

  .navbar__wrapper#navbar-short .navbar__logo {
    display: inline-block;
    cursor: pointer;
  }

  .navbar__wrapper#navbar-short .input-box {
    display: none;
  }

  .navbar__wrapper#navbar-short .navbar__user_icon {
    display: inline-block;
    display: flex;
  }

  .navbar__wrapper#navbar-short .navbar__user_icon.publish-btn {
    margin-left: auto;
    margin-right: 10px;
  }

  .navbar__wrapper .settings_title_button {
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.541);
  }

  .navbar__wrapper .settings_title_button .down-arrow {
    margin-left: 26.25px;
    margin-right: 10.25px;
  }

  .navbar__wrapper .back-button {
    margin-right: 21px;
  }

  .mobile-only {
    display: flex;
  }

  .nav-header {
    flex: 1;
    color: #f3f3ee;
    font-size: 20px;
    font-family: Nunito_Sans_Semibold;
  }
}

@media only screen and (max-width: 400px) {
  .navbar__wrapper .settings_title_button span {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
  }
}

.demoBtnNav {
  padding: 10px 16px;
  background-color: #3184c9;
  color: white;
  border: 1px solid white !important;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-weight: 600;
  font-size: 14px;
}

.demoBtnNav:hover {
  border: 1px solid rgb(49 132 201 / 50%) !important;
}

.design-navbar-selected-line {
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  height: 0.125rem;
  background-color: white;
  animation: 0.2s normal forwards lineAnimation;
}

.navbar-selected-line {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  height: 0.125rem;
  background-color: #3184C9;
  animation: 0.2s normal forwards lineAnimation;
}

@keyframes lineAnimation {
  0% {
    width: 0px;
  }

  100% {
    width: 100%;
  }
}