.element-insert-btn {
    border-radius: 5px;
    background: rgb(25, 118, 210);
    color: white;
    padding: 15px 25px;
    margin: 10px;
    font-size: 18px;
    cursor: pointer;
    height: 5vh;
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.element-description {
    display: flex;
    border-bottom: 1px solid rgb(204, 204, 204);
    padding-bottom: 30px;
}

.element-example {
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.element-example-p {
    background-color: rgba(204, 204, 204, 0.43);
    color: #545454;
    padding: 5px;
    border-radius: 5px;
    width: 20%;
    box-sizing: border-box;
}

.element-example-img {
    width: 75%;
    max-width: 100%;
    height: auto;
}