.payment_container {
    margin-top: 8.5vh;

}

.payment_wrapper {
    /* padding-top: 25px; */
    width: 70%;
    margin: auto;
}

.payment_wrapper_heading {
    line-height: 38px;
    text-align: center;
    margin-bottom: 6px;
}

.payment_right_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;
    background: #f4f6fc;
    border-radius: 10px;
    margin: 3rem;
}