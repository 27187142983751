.react-flow__attribution {
  display: none;
}

.react-flow {
  background-color: white;
}

.react-flow__handle {
  padding: 3px;
  border: unset !important;
  min-height: 0px !important;
  height: 0px !important;
  width: 40px !important;
  border-radius: 10px !important;
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.surveyOptionSelect {
  width: 50%;
}

.conditionInput {
  border: 1px solid rgba(149, 149, 161, 0.2);
  background-color: white;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
  width: 14rem;
  font-family: Nunito_Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #6b6f74;
  margin-left: 1rem;
}

.condition-close-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}