.TC1App {
    background: white;
    background-position: center;
    min-height: 100vh;
    font-family: Nunito_Sans;
    color: black;
}

.TC1template {
    width: 90%;
    margin: auto;
    padding-top: 12vh;
}

.TC1table {
    /* font-family: arial, sans-serif; */
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto;
    display: inline-block;
}

.TC1table td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.TC1table tr:nth-child(even) {
    /* background-color: #dddddd; */
}