.edit-input-text {
    overflow-y: auto;
    overflow-x: hidden;
    height: auto !important;
    max-height: auto;
    min-height: auto !important;
    white-space: pre-wrap !important;
    font-family: Poppins;
}

input[type=date]::-webkit-datetime-edit {
    /* color: #999; */
    opacity: 0.7;
}

/* ///////////// react-beautiful-dnd///////////////////// */
.remove-gutter {
    --bs-gutter-x: 0rem !important;
}

.dragstyle {
    /* top: auto !important; */
    left: auto !important;
}

.dragstyle1 {
    top: auto !important;
    /* left: auto !important; */
}

/* /////////////////Flip Card////////////////////// */

.FC5card {
    height: 350px;
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
        0px 8px 16px rgba(0, 0, 0, 0.1), 0px 16px 32px rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
    /* animated interactivity */
    transition: 250ms;
    transform-style: preserve-3d;
    transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));
}

/* when card is clicked adding .side class */
.FC5side {
    /* background: var(--grad-two); */
    transform: rotateY(-180deg);
}

.FC5cardImg {
    width: 350px;
    height: 350px;
    display: flex;
    /* justify-content: center;
    align-items: center; */
}

.FC5cardImgShow {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* card content */
.FC5card .FC5front,
.FC5card .FC5back {
    position: absolute;
    /* padding: 1rem; */
    backface-visibility: hidden;
}

.FC5card .FC5front {
    transform: rotateY(0deg);
}

.FC5card.FC5side .FC5front {
    opacity: 0;
    display: none;
}

.FC5card .FC5back {
    transform: rotateY(180deg);
}


.FC5msg {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    text-align: center;
    font-size: 0.8rem;
    padding: 8px;
    backdrop-filter: blur(10px);
    margin: 1px;
}

.FC5msgnone {
    display: none;
}

.two-line-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Show only two lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

@media (max-width: 750px) {

    .FC5cardImg {
        width: 100%;
    }

    .FC5front {
        width: 100%;
    }

    .FC5back {
        width: 100%;
    }
}