.ASN1collaps-sidebar-open {
    position: fixed;
    z-index: 9999;
    right: 0px;
    height: calc(100% - 0rem);
    width: 32rem;
    opacity: 1;
    transition: right 0.5s ease-in-out 0s, opacity 0.3s ease-in-out 0s;
    /* margin-top: 1rem; */
}

.ASN1collaps-sidebar-close {
    position: fixed;
    z-index: 9999;
    right: -17.875rem;
    height: calc(100% - 0rem);
    width: 17.875rem;
    opacity: 1;
    transition: right 0.5s ease-in-out 0s, opacity 0.3s ease-in-out 0s;
    /* margin-top: 1rem; */
}

.ASN1collaps-sidebar-icon-open {
    position: absolute;
    inset: 0px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 1.5rem;
    height: 6.5rem;
    background: transparent;
    box-shadow: none;
    border-radius: 0px;
    cursor: pointer;
    transform: translateX(-98%);
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0s;
    opacity: 1;
}

.ASN1collaps-sidebar-icon-close {
    position: absolute;
    inset: 0px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 1.5rem;
    height: 6.5rem;
    background: transparent;
    box-shadow: none;
    border-radius: 0px;
    cursor: pointer;
    transform: translateX(-98%);
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0s;
    opacity: 1;
}

.ASN1collaps-sidebar-img {
    width: 2rem;
    height: 6.5rem;
}

.ASN1collaps-sidebar-container-open {
    background-color: rgb(255, 255, 255);
    top: 0px;
    left: 0px;
    position: absolute;
    max-width: 32rem;
    min-width: 32rem;
    height: 100%;
    transition: right 0.5s ease-in-out 0s, width 0.2s ease-in-out 0s;
    box-shadow: rgba(0, 15, 51, 0.2) 0px 1px 0.25rem;
    overflow: auto;

}

.ASN1collaps-sidebar-container-close {
    background-color: rgb(255, 255, 255);
    top: 0px;
    left: 0px;
    position: absolute;
    max-width: 17.875rem;
    min-width: 17.875rem;
    height: 100%;
    transition: right 0.5s ease-in-out 0s, width 0.2s ease-in-out 0s;
    box-shadow: rgba(0, 15, 51, 0.2) 0px 1px 0.25rem;
    overflow: auto;
}


.ASN1row_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    margin: 12px 5px;
}

.ASN1row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 19rem;
}



.ASN1button {
    border: 1px solid #cfcfcf;
    background-color: transparent;
    padding: 12px 15px;
    border-radius: 8px;
    color: black;
    cursor: pointer;
}

.ASN1button:hover {
    background-color: #e7e7e7;

}

.ADP1icon {
    /* font-size: 18px; */
    color: #3184c9;
    /* cursor: pointer; */
}

.ADP1icon:hover {
    color: #0E2C45;
}

.age-selector-container {
    position: relative;
    width: 90%;
    margin: 0px 0px 0px 30px;
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
}

.responses,
.input-title,
.country-selector-container,
.language-selector-container,
.gender-selector-container,
.devices-selector-container,
.type-selector-container,
.industry-selector-container,
.income-selector-container,
.companySize-selector-container,
.education-selector-container,
.skills-selector-container,
.job-selector-container {
    position: relative;
    width: 300px;
    margin: 30px 0px 0px 40px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    width: "90%";
    cursor: pointer;
    /* padding: 10px; */
}

.dropdown-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid #d1d5db; */
    border-radius: 4px;
    padding: 10px;
    /* background-color: #f9f9fc; */
    cursor: pointer;
    position: relative;
    font-size: 14px;
    color: #6b7280;
}

.dropdown-field:hover {
    border-color: #a0aec0;
}

.dropdown-arrow {
    margin-left: 10px;
    color: #6b7280;
    font-size: 12px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #d1d5db;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
    padding: 10px 0;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 14px;
    color: #374151;
}

.dropdown-item:hover {
    background-color: #f3f4f6;
}

.dropdown-item input {
    cursor: pointer;
}

.dropdown-field span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.horizontal-slider {
    width: 100%;
    height: 8px;
    position: relative;
    border-radius: 4px;
}

.track {
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 4px;
}

.thumb {
    height: 16px;
    width: 16px;
    background-color: #007BFF;
    border-radius: 50%;
    cursor: grab;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.model-content {
    height: 90vh;
}

.inside-form {
    width: 90%;
}

.responses {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding-bottom: 15px;
}

.cost {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10px 30px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    gap: 60px;
}

.type-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.type-options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 30px;
}

@media screen and (max-width: 750px) {

    .cost,
    .input-title,
    .country-selector-container,
    .language-selector-container,
    .gender-selector-container,
    .devices-selector-container,
    .type-selector-container,
    .industry-selector-container,
    .income-selector-container,
    .companySize-selector-container,
    .education-selector-container,
    .skills-selector-container,
    .job-selector-container {
        flex-direction: column;
        padding: 10px 0;
        align-items: flex-start;
        margin: 10px 5px;
    }

    .responses {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin: 15px 0px 10px 5px;
    }

    .model-content {
        height: 95vh;
    }

    .inside-form {
        width: 100%;
    }

    .type-options {
        flex-direction: column;
    }
}