.LeftSidebarMainContainer {
  width: 180px;
  height: calc(100vh - 64px);
  background-color: white;
  /* overflow: hidden auto; */
}

.LeftSidebarContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 2px solid #dbdfe8;
}

.LeftSidebarTab {
  margin: 0.6rem 0.5rem;
  padding: 5px;
  cursor: pointer;
  font-weight: 700;
  position: relative;
}

.selected-line {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  height: 0.15rem;
  background-color: #3184C9;
  animation: 0.2s normal forwards lineAnimation;
}

@keyframes lineAnimation {
  0% {
    width: 0px;
  }

  100% {
    width: 100%;
  }
}