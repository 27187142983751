.DSP1App {
    text-align: center;
    background-color: white;
    height: 91.5vh;
    font-family: "Mulish";
    margin-top: 8.5vh;
    overflow: hidden;
}

.DSP1template {
    height: 100%;
}

@media only screen and (max-width: 800px) {

    .DSP1App {
        margin-top: 7vh;
    }

    .DSP1template {
        width: 100%;
    }

}

.DSP1settings_page {
    height: 100%;
    overflow: auto;
}

.DSP1settings_page .modal_header {
    font-family: "Mulish";
    font-size: 18px;
    /* font-weight: 600; */
    text-align: center;
    line-height: 27px;
    /* margin-top: 42px; */
    margin-bottom: 20px;
    color: black;
    background-color: #fbfbf7;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 2rem;
}

.DSP1settings_page .settings-error {
    color: #e30000;
    text-align: center;
    padding-bottom: 2vh;
}

.DSP1settings_page>.row {
    margin-left: 0;
    margin-right: 0;
    /* min-height: calc(100% - 119px); */
    height: 60vh;
    overflow: hidden auto;
}

.DSP1settings_page .column-left {
    padding: 0 23px;
    padding-right: 47.5px;
}

.DSP1settings_page .column-right {
    padding: 0 23px;
    padding-left: 47.5px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 30px;
}

/* form fields */
.DSP1settings_page .form_field {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.DSP1settings_page .form_field label {
    font-size: 16px;
    line-height: 22px;
    color: #474747;
    display: block;
    margin-bottom: 8px;
}

.DSP1settings_page .form_field .title_input {
    border-bottom: 1px solid #92909a;
    padding-bottom: 6px;
    font-size: 16px;
    font-family: Nunito_Sans_Bold;
}

.DSP1settings_page .form_field .description_input {
    border: 1px solid #92909a;
    border-radius: 8px;
    padding: 10px;
    outline: none;
    font-size: 16px;
    line-height: 22px;
}

/* radio boxes */

.DSP1settings_page .form_field .radio_boxes {
    display: flex;
    align-items: center;
    padding: 30px;
}

.DSP1settings_page .form_field .radio_box {
    margin-right: 24px;
    display: flex;
    align-items: center;
}

.DSP1settings_page .form_field .radio_box img {
    margin-right: 13px;
    cursor: pointer;
}

.DSP1settings_page .form_field .radio_box .visibility_input {
    display: none;
}

.DSP1settings_page .form_field .radio_box p {
    font-family: Nunito_Sans_Semibold;
}

/* column right */

.DSP1settings_page .image_column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DSP1settings_page .image_column .image_title {
    font-size: 16px;
    line-height: 22px;
    color: #474747;
    margin-bottom: 8px;
}

.DSP1settings_page .image_column .cover_img_container {
    display: flex;
    position: relative;
    margin-bottom: 1rem;
}

.DSP1settings_page .image_column .cover_img_container .edit-duonut-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 32px;
}

.DSP1settings_page .image_column .cover_img_container img {
    margin-bottom: 8px;
    width: 100%;
    /* max-width: 700px; */
    max-height: 350px;
    object-fit: contain;
}

.DSP1settings_page .logo_image_column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.DSP1settings_page .logo_image_column .logo_image_title {
    font-size: 16px;
    line-height: 22px;
    color: #474747;
    margin-bottom: 8px;
}

.DSP1settings_page .logo_image_column .endpage_logo_container {
    display: flex;
    position: relative;
    margin-bottom: 1rem;
}

.DSP1settings_page .logo_image_column .endpage_logo_container .edit-duonut-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 32px;
}

.DSP1settings_page .logo_image_column .endpage_logo_container img {
    /* margin-bottom: 8px; */
    width: 100%;
    /* max-width: 700px; */
    max-height: 100px;
    object-fit: contain;
}

.DSP1settings_page .save-btn-container {
    /* position: absolute; */
    /* bottom: 0; */
    /* left: 0; */
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.DSP1settings_page .action-btn,
.DSP1settings_page .publish-btn {
    padding: 12px 32px;
    font-family: "Mulish";
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}

.DSP1settings_page .publish-btn#disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: auto;
}

.DSP1settings_page .save-btn {
    /* background-color: #DBD9D9;
    color: #000; */
    margin-right: 20px;
}

.DSP1settings_page .duonut-categories {
    padding: 14px 15px;
    border: 1px solid #92909A;
    border-radius: 8px;
    /* max-height: 70px;
    overflow: auto; */
}

.DSP1settings_page .duonut-categories .duonut-category {
    /* flex: 1; */
    display: flex;
    align-items: center;
}

.DSP1settings_page .duonut-categories .category-input-container {
    display: flex;
    align-items: center;
}

.DSP1settings_page .duonut-categories .duonut-category input {
    -webkit-appearance: none;
    appearance: none;
    width: 1rem;
    height: 1rem;
    border: 1px solid;
    border-radius: 50%;
    position: relative;
}

.DSP1settings_page .duonut-categories .duonut-category input::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    display: block;
    border-radius: 50%;
    width: 0.65rem;
    height: 0.65rem;
}

.DSP1settings_page .duonut-categories .duonut-category input:checked::before {
    background-color: #8F1B77;
}

.DSP1settings_page .duonut-categories .duonut-category .category-name {
    margin-left: 0.5rem;
}

.password_input {
    border-bottom: 1px solid #92909a;
    padding-bottom: 6px;
    font-size: 16px;
    font-family: Nunito_Sans_Bold;
}


.setting-header-label {
    margin: 8px;
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.setting-header-label:hover {
    color: #3184C9;
}

.setting-selected-line {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    height: 0.15rem;
    background-color: #3184C9;
    animation: 0.2s normal forwards lineAnimation;
}

@keyframes lineAnimation {
    0% {
        width: 0px;
    }

    100% {
        width: 100%;
    }
}


.setting-visibility {
    border: 1px solid gray;
    border-radius: 10px;
    cursor: pointer;
}

.checkbox-slider {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 10px;
}

.checkbox-slider input {
    display: none;
}

.slider-span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider-span:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 0px;
    bottom: -4px;
    background-color: gray;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider-span:before {
    background-color: #3184C9;
}

input:focus+.slider-span {
    /* box-shadow: 0 0 1px #3184C9; */
}

input:checked+.slider-span:before {
    transform: translateX(18px);
}

/* 
  .slider-span.round {
    border-radius: 34px;
  }
  
  .slider-span.round:before {
    border-radius: 50%;
  } */
.setting-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.custom-tooltip {
    background: white !important;
    color: black !important;
    border: 1px solid black !important;
    padding: 5px !important;
    border-radius: 4px;
}

@media only screen and (max-width: 1100px) {

    .DSP1settings_page .row {
        min-height: auto;
    }

    .DSP1settings_page {
        /* width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        bottom: 0%;
        align-items: stretch;
        overflow-y: auto;
        max-width: 100%; */
    }

    .DSP1settings_page .row {
        display: block;
    }

    .DSP1settings_page .column-left {
        padding: 0 22px;
        /* padding-right: 47.5px; */
    }

    .DSP1settings_page .column-right {
        padding: 0 23px;
        padding-bottom: 30px;
    }

    .DSP1settings_page .close-icon {
        display: flex;
    }

    .DSP1settings_page .save-btn-container {
        position: static;
    }

    .DSP1settings_page .image_column .cover_img_container {
        display: flex;
        position: relative;
        margin-bottom: 0;
    }
}