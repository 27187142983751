.HTF1App {
    text-align: center;
    background-color: white;
    height: 100vh;
    font-family: "Mulish";
    margin-top: 8.5vh;
}

.HTF1template {}

.HTF1section-1 {
    /* background: url(../../assets/Images/bg-bottom-hero.png),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #3184C9 -12.18%, #8f1b77 108.63%); */
    background-color: #f5f5f5 !important;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 82vh;
}

.HTF1section-1-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: black;
}

.HTF1section-1-left {
    width: 50%;
    padding-left: 5rem;
    padding-top: 3rem;
}

.HTF1section-1-right {
    width: 50%;
    height: 72vh;
    padding: 2rem;
}

.HTF1section-1-right-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.HTF1table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    padding: 4rem 5rem;
    border-color: #f5f5f5;
    /* border-width: 2px; */
    border-spacing: 0px;
    border-collapse: unset;
}

.HTF1table th {
    font-size: 28px;
    font-weight: 600;
}

.HTF1table img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin: 0 auto;
    display: block;
}

.HTF1table td,
th {
    border: 1px solid #e9ecef;
    text-align: left;
    padding: 20px 30px;
    border-color: #E0E9EF;
    /* border-width: 2px; */
    border-spacing: 0px;
    border-collapse: unset;
}

.helpmesssage {
    padding: 20px;
    margin-bottom: 70px;
    margin-left: 6rem;
    margin-right: 6rem;
}

.helpmesssage .imgcontainer img {
    width: 100%;
    max-width: 600px;
}

.helpmesssage .message__column {
    display: flex;
    align-items: center;
    text-align: left;
}

.helpmesssage .message__column p {
    line-height: 30px;
    opacity: 0.8;
    padding: 1.2rem 0rem;
}

@media only screen and (max-width: 800px) {

    .HTF1App {
        margin-top: 7vh;
    }

    .HTF1section-1 {
        height: 110vh;
    }

    .HTF1section-1-inner {
        flex-direction: column;
    }

    .HTF1section-1-left {
        width: 100%;
        padding: 3rem 0px
    }

    .HTF1section-1-right {
        width: 100%;
        height: 35vh;
        padding: 0rem;
    }

    .HTF1table {
        padding: 0rem;
    }

    .HTF1table th {
        font-size: 20px;
    }

    .HTF1table td,
    th {
        padding: 10px;
    }

    .helpmesssage {
        padding: 10px;
        margin-left: 0rem;
        margin-right: 0rem;
    }

}