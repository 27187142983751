.HMP1Footer {
    /* padding: 2px 50px; */
    padding-bottom: 30px;
    padding-top: 20px;
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    color: rgba(11, 35, 48, 0.8);
    font-family: "Mulish";
    position: relative;

}

.HMP1FooterContainer {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 3rem 0px;
    color: black;
    width: 90%;
    margin: auto;
    padding-left: 3rem;
    text-align: left;
}

.HMP1FooterContainer_inner {
    width: 22%;
}

.HMP1FooterContainer_inner div div:hover {
    color: rgba(11, 35, 48, 0.8);

}

.HMP1copyright {
    padding-bottom: 3rem;
    text-align: center;
}

.HMP1FooterContainer_inner a {
    display: block;
    cursor: pointer;
    margin: 5px;
    color: black;
}

.HMP1FooterContainer_inner div {
    cursor: pointer;
    margin: 5px;
}

.HMP1buttons {
    border: 1px solid #3184C9;
    background-color: white;
    padding: 12px 20px;
    border-radius: 6px;
    margin: 2vh;
    color: black;
    cursor: pointer;
    font-size: 1rem;
    width: 8rem;
}

.HMP1buttons:hover {
    background-color: #7a108f;
    color: white;
}

.HMP1heading {
    padding-bottom: 1.5rem;
    font-size: 3rem;
}

@media only screen and (max-width: 600px) {
    .HMP1Footer {
        padding-bottom: 0px;
    }

    .HMP1FooterContainer {
        justify-content: center;
        flex-direction: column;
        padding-left: 0rem;

    }

    .HMP1FooterContainer_inner {
        width: 100%;
        text-align: center;
        padding: 1rem 0rem;
    }

    .HMP1heading {
        font-size: 1.5rem;
    }
}