.QRC1modalMain {
  text-align: center;
  margin-top: 22vh;
  border-radius: 2vh;
  /* border: 2px solid #C00DE4; */
}

.QRC1Continuebtn {
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  padding: 8px 20px;
  border-radius: 5px;
  margin: 2vh;
  color: black;
  cursor: pointer;
}

.QRC1Continuebtn:hover {
  background-color: #0E2C45;
  border: 1px solid #0E2C45;
  color: white;
}

.modal-backdrop {
  z-index: -1;
}

.qrcodeImage {
  width: 100%;
  height: 40vh;
  object-fit: contain;
}

@media (max-width: 800px) {}