.NM1modalMain {
    text-align: center;
    margin-top: 26vh;
    border-radius: 2vh;
    border: 1px solid #C00DE4;
}
.NM1Continuebtn {
    border: 1px solid #C00DE4;
    background-color: white;
    padding: 8px 20px;
    border-radius: 6px;
    margin: 2vh;
    color: white;
    cursor: pointer;
}
@media (max-width: 800px) {
    
}