.menu_container {
  width: 100%;
  overflow-x: hidden;
}

.menu_container .modal-overlay {
  position: fixed;
}

.menu_wrapper {
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 80%;
}

.menu_container .menu {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu_container .menu_item {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
}
.menu_container .menu_item:hover {
  background-color: #3184C9;
  color: white;
}
.menu_container .menu_item .menu_text {
  font-family: Nunito_Sans_Bold;
}

.menu_container .menu_item .icon {
  width: 7px;
  margin-left: 17px;
}

/* menu bottom */

.menu_container .menu_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.menu_container .menu_buttons .sign-in-btn {
  margin-right: 16px;
  background-color: white;
  transition: 0.2s;
  color: black;
  font-family: Nunito_Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.menu_container .menu_buttons .sign-in-btn:active {
  background-color: rgba(219, 217, 217, 1);
}

.menu_container .menu_social_media_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.menu_container .menu_social_media_icons .social-media-icon {
  margin-right: 16px;
  opacity: 0.5;
}

.menu_buttons .sign-up-btn {
  padding: 10px 17px;
}