.home_page_integration_section {
    margin: 6rem auto;
}

.home_page_integration_section_images {
    text-align: center;
}

.home_page_integration_section_images img {
    width: 180px;
    height: auto;
    padding: 8px;
}

.home_page_section_header {
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 3rem;
    text-align: center;
}

.homepageAiContainer1 {
    top: 64px;
    left: 0;
    background-color: #3184c9;
    /* border: 2px solid black; */
    color: white;
    position: fixed;
    width: 100%;
    padding: 0.5rem;
    text-align: center;
    z-index: 99;
    font-size: 1rem;
}

.tickmark-icon {
    height: 28px;
    width: 30px;
}

.HPS1secMain {
    display: flex;
    justify-content: space-between;
}

.HPS1secContainer {
    display: flex;
    flex-direction: column;
    width: 22%;
    margin: 1rem;
    align-items: center;
}

.HPS1secImgContainer {
    background-color: #f4f9fc;
    padding: 1rem;
    border-radius: 16px;
}

.HPS1secimage {
    width: 3rem;
    height: 3rem;
}

#services .swiper-slide {
    transition-duration: 1s !important;
}

.createFreeFormButton {
    padding: 14px 16px;
    background-color: #3184c9;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
}

.createFreeFormButton:hover {
    border: 1px solid #3184c9;
}

.home_page_customer_review_Container {
    padding: 30px 30px 20px;
    background: #fff;
    transition: all .6s ease-in-out;
    border: 1px solid #e7e7e7;
    width: 50% !important;
    margin: auto;
    display: flex !important;
    height: 25rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.home_page_casestudy_HeaderTab {
    position: relative;
    font-weight: 400;
    font-size: 20px;
    color: black;
    margin-bottom: 35px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_page_casestudy_HeaderTabInner {
    width: auto;
    padding: 1rem;
    border-radius: 15px;
    background-color: #eaecef7a;
    /* box-shadow: 0 3px 11px rgba(22, 27, 47, .12); */
}

.home_page_casestudy_header-label {
    margin: 8px 10px;
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 15px;
    text-align: center;
}

.home_page_casestudy_mainConatiner {
    padding: 30px 30px 20px;
    background: #fff;
    transition: all .6s ease-in-out;
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    box-shadow: 0 3px 11px rgba(22, 27, 47, .12);
    width: 60%;
    margin: auto;
    display: flex !important;
    height: 26rem;
    align-items: center;
    justify-content: center;
}

.home_page_casestudy_innerConatiner {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 2rem;
}


@media only screen and (max-width: 1100px) {

    .home_page_integration_section_images img {
        width: 140px;
    }

    .home_page_section_header {
        font-size: 1.4rem;
    }

    .homepageAiContainer1 {
        display: none;
    }

    .HPS1secMain {
        flex-direction: column;
    }

    .HPS1secContainer {
        width: 90%;
    }

    .home_page_customer_review_Container {
        width: 100% !important;
        height: 35rem !important;
    }

    .home_page_casestudy_HeaderTabInner {
        display: flex;
        flex-direction: column;
    }

    .home_page_casestudy_mainConatiner {
        width: 100%;
        flex-direction: column;
        height: auto;
    }

    .home_page_casestudy_innerConatiner {
        width: 100%;
    }

}