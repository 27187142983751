.CATTP1App {
    text-align: center;
    background-color: white;
    background-size: contain;
    /* font-family: Poppins; */
    margin-top: 8.5vh;
    text-align: center;
    overflow-x: hidden;
    color: black;
}

.CATTP1templateblur {
    backdrop-filter: blur(15px);
    min-height: 91.5vh;
    display: flex;

}

.CATTP1template {
    display: flex;
}

.CATTP1leftContainer {
    width: 15%;
    padding: 2rem 1rem;
    background-color: rgb(243, 243, 241);
}

.CATTP1rightContainer {
    width: 85%;
    height: 91.5vh;
    overflow: auto;
}

.CATTP1activitytype {
    padding: 6px 8px;
    text-align: left;
    cursor: pointer;
    color: rgba(11, 35, 48, 0.8);
}

.CATTP1activitytype:hover {
    font-weight: 600;
    color: rgba(11, 35, 48, 1);
}

.CATTP1PrebuilteButton {
    padding: 1rem;
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    margin: 0.5rem auto;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    position: absolute;
    right: 1rem;
    top: 2rem;
    z-index: 1;
}

.CATTP1PrebuilteButton:hover {
    background-color: #0E2C45;
    color: white;
}

@media only screen and (max-width: 600px) {
    .CATTP1App {
        margin-top: 6vh;

    }

    .CATTP1leftContainer {
        display: none;
    }

    .CATTP1rightContainer {
        width: 100%;
    }

    .CATTP1PrebuilteButton {
        position: relative;
        right: unset;
    }
}