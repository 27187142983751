.DPM1App {
    text-align: center;
    background-color: white;
    background-size: contain;
    font-family: Poppins;
    margin-top: 8.5vh;
    text-align: center;
    overflow-x: hidden;
    color: black;
}
.DPM1iframe{
    width: 100%;
    height: 91.5vh;
    padding: 0;
    margin: 0;
}
@media only screen and (max-width: 600px) {
    .DPM1App {
        margin-top: 5vh;

    }
}