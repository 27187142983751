.SlackIntegrationButton {
    padding: 10px 18px;
    background-color: black;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.SlackIntegrationButton:hover {
    opacity: 0.8;
}

.saveIntegrationButton {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 18px;
    background-color: black;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.saveIntegrationButton:hover {
    opacity: 0.8;
}