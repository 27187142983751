.chartContainer {
  text-align: center;
  font-family: "Mulish";
  margin-top: 10vh;
}

.rdt_TableHeadRow {
  background-color: black !important;
  color: white !important;
  font-size: 2vh !important;
  font-weight: 700 !important;
}

.rdt_TableHeader {
  text-align: left !important;
}

.rdt_TableHeader .kNxEtY {
  font-weight: 700 !important;
}

.DRC1table {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  flex-direction: column;
}

.DRC1table table {
  border: 2px solid black;
  width: 800px;
}

.DRC1table th {
  padding: 1vh;
  background: #8F1B77;
  color: white;
  text-align: center;
}

.DRC1table tr {
  border: 1px solid black;
  padding: 2px;
}

.DRC1table td {
  text-align: center;
  background: aliceblue;
  padding: 1.5vh;
}

.report_btn-edit {
  padding: 8px;
  width: 3.5rem;
  margin: 4px;
  cursor: pointer;
  border-radius: 8px;
}

.report_btn-delete {
  padding: 8px;
  width: 3.5rem;
  margin: 4px;
  cursor: pointer;
  color: rgb(202, 0, 0);
  border-radius: 8px;
}

.DR1linechart {
  display: inline-block;
  margin: 1rem auto;
}

.DR1linecharfilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 780px;
  margin: auto;
}