.success_modal .success_message {
   /* color: #30C435; */
   color: black;
   font-size: 28px;
   margin-bottom: 22px;
   text-align: center;
}

.success_modal .buttons {
   font-size: 14px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 50px;
}

.success_modal .secondary-btn {
   font-family: "Mulish";
   font-weight: 600;
   background-color: transparent;
   margin-right: 40px;
   cursor: pointer;
}

.success_modal .btn-primary {
   font-family: "Mulish";
   font-weight: 600;

}

.success_modal .description {
   color: rgba(71, 71, 71, 1);
   text-align: center;

}

.success_modal.modal_container .modal_basic .menu_header {
   display: none;
}

.publish_modal_btn {
   font-family: "Mulish";
   font-weight: 600;
   background-color: white;
   border: 1px solid black;
   color: black;
   border-radius: 5px;
   padding: 8px 16px;
   font-size: 14px;
   cursor: pointer;
   min-width: 8rem;
   margin: 0rem 0.5rem;
}

.publish_modal_btn:hover {
   color: white;
   background-color: #0E2C45;
}

.publishIcons {
   position: relative;
   padding: 2px;
   margin: 4px;
   font-size: 3vh;
   height: 6vh;
   width: 5vh;
   color: white;
   cursor: pointer;
   display: inline-block;
   box-sizing: border-box;
}

.publishIcons:hover {
   -ms-transform: scale(1.1);
   /* IE 9 */
   -webkit-transform: scale(1.1);
   /* Safari 3-8 */
   transform: scale(1.1);
}

.publish_modal_copy_btn {
   font-weight: 800;
   border: 1px solid #ecebeb;
   border-radius: 0px 5px 5px 0px;
   padding: 10px;
   font-size: 1.2rem;
   height: 42px;
   cursor: pointer;
   border-left: none;
   display: flex;
   align-items: center;
}

.publish_modal_copy_btn:hover {
   color: #3184C9;
}

.publish_modal_edit_btn {
   font-family: "Mulish";
   font-weight: 600;
   background-color: #3184C9;
   border: 1px solid white;
   color: white;
   border-radius: 5px;
   padding: 8px 16px;
   font-size: 14px;
   cursor: pointer;
   min-width: 8rem;
   margin: 0.5rem;
}

.publish_modal_edit_btn:hover {
   color: white;
   background-color: #0E2C45;
}

.publish_modal_duonut_btn {
   font-family: "Mulish";
   font-weight: 600;
   background-color: #0E2C45;
   color: white;
   border-radius: 5px;
   padding: 8px 16px;
   font-size: 14px;
   cursor: pointer;
   min-width: 8rem;
   margin: 0.5rem;
}

.publish_modal_duonut_btn:hover {
   background-color: white;
   border: 1px solid black;
   color: black;
}

#embediframearea {
   overflow: hidden;
}

#embediframearea:focus {
   overflow: auto;
}

@media only screen and (max-width: 768px) {

   .success_modal.modal_container .modal_basic {
      min-height: 100vh;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding: 0 !important;
      max-width: 1000px;
   }

   .success_modal.modal_container .modal_basic .menu_header {
      padding-top: 24.85px;
      padding-bottom: 20.85px;
      display: block;
   }

   .success_modal .modal_basic_content {
      padding: 16px;
      padding-top: 150px;
   }

   .success_modal .success_message {
      font-size: 20px;
      margin-bottom: 24px;
   }

   .success_modal .buttons {
      flex-direction: column;
      align-self: stretch;
   }

   .success_modal .secondary-btn {
      margin-right: 0;
      order: 2;
   }

   .success_modal .btn-primary {
      padding-top: 13px;
      padding-bottom: 13px;
      margin-bottom: 24px;
   }

   .success_modal .buttons button {
      width: 100%;
   }
}