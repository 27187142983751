.designMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.elementsContainer {
  background-color: white;
  position: absolute;
  width: 1024px;
  height: 575px;
  transform: scale(0.80);
  /* overflow: hidden; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.designBarContainer {
  position: absolute;
  top: 0;
  background-color: white;
  width: 62vw;
  height: 2.5rem;
  display: flex;
  justify-content: flex-end;
}

.designBarIcon {
  padding: 4px;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
}

.selectedDesignBarIcon {
  padding: 4px;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: #3184c933;
  border: 1px solid rgb(49, 132, 201);
  border-radius: 5px;
}


.selected {
  pointer-events: none;
  position: absolute;
  z-index: 99;
  border: 2px solid aqua;
}

/* pos guidelines */
.moveable-normal.red {
  background: red !important;
}

/* gap guidelines */
.moveable-gap.red {
  background: red !important;
}

/* When snapped to an element in elementGuidelines */
.moveable-bold.red {
  background: red !important;
}

/* A dashed line between target and element */
.moveable-dashed.red {
  border-top-color: red !important;
  border-left-color: red !important;
}

/* pos guidelines */
.moveable-normal.green {
  background: green !important;
}

/* gap guidelines */
.moveable-gap.green {
  background: green !important;
}

/* When snapped to an element in elementGuidelines */
.moveable-bold.green {
  background: green !important;
}

/* A dashed line between target and element */
.moveable-dashed.green {
  border-top-color: green !important;
  border-left-color: green !important;
}