/* form modal */
.form_modal {
  background-color: rgb(255, 255, 255);
  width: 880px;
  border-radius: 8px;
  overflow: hidden;
}

.form_modal .col-lg-6 {
  padding: 0;
}

.form_modal .form-column {
  position: relative;
}

/* bg color of close icon */

.form_modal__header {
  margin-bottom: 16px;
}

/* form img column */
.form-img-container {
  display: flex;
}

.form_modal .form-img {
  width: 100%;
  max-width: 414px;
  min-height: 95vh;
}

/* form column */
.form_modal__form {
  /* margin-top: 46px;
  padding: 0 47px 0 20px; */
  background: border-box;
  margin: 0;
  padding: 70px;
  box-shadow: 0px 0px 9px 3px #eaecf1;
  border-radius: 3px;
  font-family: Arial;
}

/* HEADER */
.form_modal__form .form_modal__header {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  /* color: var(--primary); */
  text-align: left;
}

.form_modal__form .form_modal__header1 {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: var(--primary);
  text-align: left;
}

.form_modal__form .form_modal__signin-header,
.form_modal__form .form_modal__signup-header {
  text-align: center;
}

/* HEADER BACK BTN */

/* hide back btn for desktop */
.signup-form .modal_back_btn,
.sign-in-form .modal_back_btn {
  display: none;
}

.form-label {
  font-family: Inter_Semibold;
}

/* signin form */
.form_modal__signin-header {
  margin-bottom: 20px;
}

.form_modal__btn-secondary {
  background-color: var(--grey);
  width: 100%;
  margin-bottom: 12px;
  padding: 10px 16px;
}

/* form signin with google */
.form_modal__form .form_modal__btn-secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  font-family: "Mulish";
  width: auto;
  background-color: white;
  padding: 13px 16px;
  cursor: pointer;
  box-shadow: rgba(61, 59, 59, 0.1) 0px 2px 10px;
}

.form_modal__form .form_modal__btn-secondary .google-icon {
  margin-right: 1rem;
  width: 1.5rem;
}

.form_modal__form .remember-me .text {
  margin-left: 8px;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}

.form_modal__form .remember-me,
.remember-me input {
  cursor: pointer;
}

.form_modal__form .forgot-password {
  position: relative;
  font-weight: 700;
  font-family: "Mulish";
  cursor: pointer;
}

.text_underline {
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 100%;
  height: 0.3px;
  background-color: #000;
}

/* submit button */
.form_modal__submit_btn {
  width: 100%;
  padding: 13px 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  font-weight: 700;

  margin-top: 16px;
  margin-bottom: 16px;
  font-family: "Mulish";
  background-color: white;
  color: black;
  border: 2px solid black;
  cursor: pointer;
}

.form_modal__submit_btn:hover {
  background-color: black;
  color: #fff;
}

.modal_submit_btn {
  padding: 13px 20px;
  font-family: Nunito_Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  background-color: #3498db;
  border: 0;
}

.modal_submit_btn:hover {
  background-color: #308ecc;
}

.form_modal__form .form-footer {
  text-align: center;
  color: rgba(17, 17, 19, 0.6);
}

.form_modal__form .form-footer .form-link {
  position: relative;
  color: black;
  cursor: pointer;
}

/* SIGNUP FORM */
.signup-form .form_modal__signup-header {
  margin-bottom: 10px;
}

.signup-form .form_modal__form_fields .form-label {
  font-family: Inter_Semibold;
  font-size: 16px;
}

.signup-form .form_modal__submit_btn {
  margin-bottom: 11.7px;
}

.signup-form .form_modal__form_footer_container {
  /* padding-left: 25px; */
}

.signup-form .form-footer {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 4px;
  text-align: center;
}

.signup-form .form-footer .text_underline {
  bottom: 2.5px;
}

.sign-in-form .form-footer .text_underline {
  bottom: 0px;
}

/* EMAIL SIGNUP FORM */
.form_modal .form_sub_text {
  color: #a4a4a4;
  margin-bottom: 16px;
}

@media only screen and (min-width: 1100px) {
  .forgot_password_modal_container .modal_back_btn {
    display: none;
  }

  .modal_container-enter-code .modal_back_btn {
    display: none;
  }

  .change_password_modal .modal_basic {
    min-width: 376px;
  }
}

@media only screen and (max-width: 1100px) {
  .form_modal {
    width: 100%;
    height: 100vh;
    align-self: stretch;
    overflow: auto;
    border-radius: 0;
  }

  .form_modal__form {
    margin-top: 40px;
    padding: 0 35px;
  }

  .form_modal .form-column {
    width: 100%;
  }

  .form_modal .img-column {
    display: none;
  }

  /* header */

  .form_modal__form .form_modal__header {
    font-size: 20px;
    /* text-align: left !important; */
    /* padding-left: 38px; */
    font-family: Nunito_Sans_Semibold;
  }

  .form_modal__form .form_modal__header label {
    /* padding-right: 5rem; */
  }

  .form_modal__header {
    /* margin-bottom: 50px !important; */
  }

  .email-registered-form .form_modal__header {
    margin-bottom: 20px !important;
  }

  .email-signup-form .form_modal__header {
    margin-bottom: 20px !important;
  }

  .form_modal .form_sub_text {
    text-align: left;
  }

  /* back btn */
  .form_modal .modal_back_btn {
    display: inline-block;
    justify-content: center;
    align-items: center;
  }

  .forgot_password_modal_container .modal_basic {
    padding: 40px 30px;
    border-radius: 0;
  }

  .signup-form .form_modal__form_footer_container {
    /* margin-top: 11px; */
    padding-left: 0;
  }

  .forgot_password_modal_container .modal_back_btn {
    display: inline-block;
  }
}