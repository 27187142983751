.HSK1App {
    text-align: center;
    background-color: white;
    height: 100vh;
    font-family: "Mulish";
    margin-top: 8.5vh;
}

.HSK1template {
    padding: 2rem;
    width: 70%;
    margin: auto;
    text-align: left;
}

.HSK1template div {
    line-height: 30px;
}

.HSK1section-1 {
    width: 60%;
    padding: 2rem 0rem;
}


.HSK1section-1-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid rgb(231, 231, 231);
}

@media only screen and (max-width: 800px) {

    .HSK1App {
        margin-top: 7vh;
    }

    .HSK1template {
        width: 100%;
    }

    .HSK1section-1 {
        width: 100%;
    }

}