.M2modalBody{
    background: var(--ans-bgColor);
    color: var(--ans-fontColor);
    text-align: center;
    border-radius: 2vh;
    border: 1px solid var(--ans-hover-bgColor);
    margin-top: 25vh;
}
.M2correctimg{
    width: 40%;
    float: left;
    box-sizing: border-box;
}
.M2cong{
    font-size: 5vh;
    margin-top: 5vh;
}
.M2msg{
    font-size: 3vh;
}
.M2text{
    float: right;
    width: 60%;
}

.M2modalBody2{
    background: #0000004f;
    border: none;
    color: white;
    text-align: center;
    margin-top: 25vh;
    border-radius: 2vh;
    border: 1px solid var(--ans-hover-bgColor);
}
.M2correctimg2{
    width: 40%;
    margin: auto;
    box-sizing: border-box;
    display: block;
}
.M2cong2{
    font-size: 8vh;
    font-weight: 900;
    text-shadow: 2px 2px white;
    display: inline-block;
}
.M2text2{
    width: 100%;
}

.M2mtfAnswer {
    display: flex;
    justify-content: space-around;
}

.M2mtfAnswerLeft {
    float: left;
    width: 48%;
    border: 1px solid gray;
}

.M2mtfAnswerRight {
    float: right;
    width: 48%;
    border: 1px solid gray;
}
.M2mtfAnswerLeftImg{
    width: 4vh;
    height: 4vh;
}
@media only screen and (max-width: 750px) {
    .M2correctimg2{
        width: 50%;
    }
    .M2text{
        float: none;
        width: 100%;
    }
    .M2correctimg{
        width: 20vh;
        margin: auto;
        float: none;
        display: block;
    }
    .M2cong{
        font-size: 5vh;
        text-align: center;
        margin-top: 0vh;
    }
    .M2msg{
        font-size: 3vh;
        text-align: center;
    }
    .M2explain{
        text-align: center;
    }
}