.duonut {
  width: 288px;
  height: 230px;
  background: #E2F4FF;
  /* background: transparent; */
  border: 1px solid #E2F4FF;
  margin-right: 17px;
  border-radius: 8.12057px;
  margin-bottom: 1.5rem;
  /* flex: 1; */
}

.duonut:hover {
  background-color: #E2F4FF;
  color: black;
  cursor: pointer;
}

.duonut .top {
  display: flex;
  justify-content: space-between;
  margin-left: 8.1px;
  margin-right: 8px;
  margin-top: 2px;
}

.duonut .top .left {
  display: flex;
  align-items: center;
}

.duonut .top .right {
  display: flex;
  align-items: center;
}

/* .rsis-image{
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
} */

.duonut .img {
  width: 234px;
  height: 150px;
  justify-content: center;
  display: flex;
  /* margin-top: 4px; */
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}

.img img {
  width: 234px;
  height: 150px;
  position: absolute;
  left: 0;
  top: 0;
  transition: 1s;
  object-fit: cover;
}

.img img.in-active {
  transform: translateX(100%);
  opacity: 0;
}

.img img.previous {
  opacity: 0;
  transform: translateX(-100%);
}

.img img.active {
  transform: translateX(-50%);
  left: 50%;
}

.duonut .bottom {
  max-width: 100%;
  margin-top: 7px;
  margin-left: 6.67px;
  display: flex;
  justify-content: space-between;
  margin-right: 7.82px;
  height: fit-content;
  align-items: center;
}

.duonut .bottom .left .activityName {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 17rem;
  text-align: left;
}

.duonut .bottom .left .totalActivities {
  font-size: 10px;
  pointer-events: none;
  cursor: default;
}

.duonutCategotyButton {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  background: #3184C973;
  color: white;
  border-radius: 30.4521px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2px 8px;
  margin-left: 1.1rem;
}

.duonut .bottom .interactions {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 10.8274px;
  line-height: 15px;
}

.duonut .bottom .interactions span {
  margin: 0;
  margin-left: 9.56px;
}

.options {
  position: absolute;
  width: 230px;
  background: #ffffff;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.125);
  border-radius: 12px;
  z-index: 10;
  color: black;
}

.options .top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 14px;
  margin: 0;
  cursor: default;
}

.options .top .popuptitle {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #3184C9;
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
  text-align: center;
}

.options .top span {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #92909a;
}

.options .mainpopup ul .item {
  height: 35px;
  display: flex;
  align-items: center;
  border-bottom: 0.694444px solid rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.options .mainpopup ul .item span {
  margin-left: 35px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.options .mainpopup ul .item:nth-child(9) {
  border-bottom: none;
}

@media only screen and (max-width: 1100px) {
  .duonut .img {
    justify-content: center;
  }

  /* .duonut .img img {
    width: auto;
  } */

}