.nuggetApp {
    text-align: center;
    background: white;
    background-position: center;
    min-height: 100vh;
    margin-top: 7vh;
    font-family: "Mulish";
}

.nuggetTemplate {
    padding: 1rem 5rem;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;

}

.nuggetContainer1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(243, 243, 241);
    color: black;
    height: 80vh;
    width: 40%;
    border-radius: 20px;
    overflow: hidden;
}

.nuggetContainer1 * {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    box-sizing: border-box;
}

.nuggetContainer2 {
    width: 60%;
    display: flex;
    flex-direction: column;
    height: 80vh;
    overflow: auto;
}

.nuggetButton {
    color: black;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 0px;
    margin: 5px 10px;
    padding: 5px 10px;
    cursor: pointer;
}

.nuggetButton:hover {
    /* background-color: rgba(255, 255, 255, 0.1); */
    color: #3184C9;
    border: 1px solid #3184C9;
}

.nuggetButton2 {
    color: black;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 5px;
    margin: 5px 10px;
    padding: 8px 10px;
    cursor: pointer;
}

.nuggetButton2:hover {
    background-color: #0E2C45;
    color: white;
}

.nuggetDuonutContainer {
    display: flex;
    align-items: center;
    position: relative;
    margin: 5px;
    cursor: pointer;
    padding: 5px;
    border-radius: 8px;
}

.nuggetDuonutContainer:hover {
    background-color: rgb(243, 243, 241);
}

.nuggetOptions {
    padding: 6px 0px;
}

.nuggetOptions li {
    padding: 6px 6px 6px 15px;
    cursor: pointer;
    display: flex;

}

.nuggetOptions li:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 800px) {

    .nuggetApp {
        margin-top: 5vh;
    }

    .nuggetTemplate {
        flex-direction: column;
        padding: 2rem 1rem;
    }

    .nuggetContainer1 {
        width: 100%;
        height: 70%;
        padding: 20px;
    }

    .nuggetContainer2 {
        width: 100%;
        overflow: unset;
    }

}