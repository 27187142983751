.CPST1activityDuonuts {
    padding: 1rem;
}

.CPST1subtype_name {
    text-align: left;
    padding-left: 1rem;
    font-size: 1.2rem;
    padding-bottom: 1rem;
}

.CPST1templatesContainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: auto;
}

.CPST1templateCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem;
    /* border: 1px solid gray; */
    border-radius: 0.5rem;
    width: 14rem;
    cursor: pointer;
    position: relative;
}

.CPST1templateCardImgContainer {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
}

.CPST1templateCardImg {
    width: 100%;
    height: 9rem;
    object-fit: cover;
    object-position: top;
}

.CPST1templateCardText {
    text-align: left;
    width: 100%;
}

.CPST1templateCardTextInner {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px 0px 8px 2px;
}

.CPST1modalMain {
    text-align: center;
    border: 1px solid white;
    background-color: white;
    color: black;
    border-radius: 2vh;
}

.CPST1iframe {
    width: 100%;
    min-height: 72vh;
    border: none;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 5px 15px;
}

.CPST1iframeMob {
    width: 20vw;
    min-height: 72vh;
    border: none;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 5px 15px;
}

.CPST1mobileScreen {
    width: 8rem;
    background-color: white;
    height: 1.8rem;
    border-radius: 16px;
    position: absolute;
    margin: -16px;
}

.CPST1TemplateButton {
    padding: 0.5rem 1rem;
    background-color: white;
    color: black;
    border: 2px solid black;
    border-radius: 0px;
    margin: auto;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
    position: absolute;
    right: 1.5rem;
}

.CPST1close-icon {
    position: absolute;
    right: 20px;
    cursor: pointer;
}

.CPST1modalBottom {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CPST1icon {
    background-color: rgb(243, 243, 241);
    border: 1px solid rrgb(243, 243, 241);
    border-radius: 5px;
    color: rgba(11, 35, 48, 0.5);
    padding: 6px 11px 16px 11px;
    margin: 2px;
    cursor: pointer;
    height: 22px;
    width: 25px;
    font-size: 1.3rem;
}

.CPST1icon:hover {
    background-color: rgba(186, 193, 196, 0.7);
    color: rgb(11, 35, 48);
}

.CPST1templateCardHover {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity .5s;
    width: 100%;
    background-color: rgba(0, 15, 51, 0.8);
}

.CPST1templateCardHoverCircle {
    width: 2.5rem;
    height: 2.5rem;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: rgb(0 15 51 / 20%) 0px 1px 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CPST1templateCardImgContainer:has(.skeleton) {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsla(201, 20%, 80%, 0.421);
    }

    100% {
        background-color: hsla(204, 20%, 95%, 0.204);
    }
}

@media only screen and (max-width: 800px) {

    .CPST1templatesContainer {
        justify-content: center;
    }

    .CPST1TemplateButton {
        position: relative;
        right: unset;
    }

    .CPST1iframe {
        width: 90%;
    }

    .CPST1iframeMob {
        width: 90%;
    }
}