.NV1Template {
    display: flex;
    flex-direction: column;
    padding: 1rem 4rem;
}

.NV1duonutContainer {
    display: flex;
    flex-wrap: wrap;

}

.NV1duonut {
    display: flex;
    flex-direction: column;
    margin: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .NV1Template {
        padding: 1rem;
    }

}