.aboutChannelApp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutChannelContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
    margin: 1rem;
    width: 35rem;
    border: 1px solid black;
    border-radius: 10px;
}

.aboutChannelRow {
    margin: 0.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.aboutChannelInput {
    background-color: transparent;
    color: black;
    font-size: 1rem;
    width: fit-content;
    min-width: 25rem;
}

.aboutChannelbtn {
    border: 1px solid black;
    background-color: transparent;
    padding: 8px 20px;
    border-radius: 6px;
    margin: 1rem;
    color: black;
    cursor: pointer;
    font-size: 1rem;
}

.aboutChannelbtn:hover {
    background-color: #0E2C45;
    color: white;
}

@media only screen and (max-width: 800px) {
    .aboutChannelContainer {
        width: 90%;
        margin: 0;
    }

    .aboutChannelInput {
        width: auto;
        min-width: auto;
    }
}