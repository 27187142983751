.slideApp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Slide-AddPage-Button {
    padding: 0.8rem 1.5rem;
    cursor: pointer;
    text-align: center;
    background-color: #3184c933;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    margin: 1rem;
}

.Slide-AddPage-Button:hover {
    background-color: #0000003d;
}

.slideAppContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 68vh;
    overflow: hidden auto;
}

.SlideContainer {
    width: 130px;
    min-height: 50px;
    cursor: pointer;
    border: 1px solid #dbdfe8;
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin: 12px;
    position: relative;
}

.SlideContainer:hover .PMenuLineBar {
    visibility: visible;
}


.PMenuLineBar {
    position: absolute;
    /* top: 0; */
    right: 0;
    padding: 6px 4px;
    /* background-color: rgba(128, 128, 128, 0.8); */
    border-radius: 6px;
    margin: 5px;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PMenuLineBar:hover {
    /* background-color: #3184C9; */

}

.PMenuOptions-1 {
    position: absolute;
    width: 130px;
    background: #f7f6f6;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    z-index: 99999;
    /* margin: -10rem 0rem 0rem 6rem; */
}

.PMenuOptions-1 .top {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    cursor: default;
    padding: 0px 10px;
}

.PMenuOptions-1 .top .popuptitle {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 22px;
    color: #3184C9;
    margin-top: 0.875rem;
    margin-bottom: 0.5rem;
    word-break: break-word;
    /* text-align: center; */
}

.PMenuOptions-1 .top span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #92909a;
}

.PMenuOptions-1 .mainpopup ul .item {
    height: 36px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    border-bottom: 0.694444px solid rgba(0, 0, 0, 0.19);
    cursor: pointer;
}

.PMenuOptions-1 .mainpopup ul .item span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}

.PMenuOptions-1 .mainpopup ul .item:nth-child(7) {
    border-bottom: none;
}