.SPI1App {
    text-align: center;
    background: white;
    background-position: center;
    min-height: 100vh;
    margin-top: 7vh;
    font-family: "Mulish";
}

.SPI1Template {
    color: black;
    min-height: 100vh;
    padding-top: 2rem;
}

.SPI1Container1 {
    padding: 1rem 8rem;
    margin: 0;
}

@media only screen and (max-width: 800px) {
    .SPI1Container1 {
        padding: 1rem;
    }

}