.channelApp {
    text-align: center;
    background: white;
    background-position: center;
    min-height: 100vh;
    margin-top: 7vh;
    font-family: "Mulish";
}

.channeltemplate {
    color: black;
    min-height: 100vh;
    padding-top: 2rem;
}

.channelContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.channelHeading {
    margin-left: 1rem;
    text-align: left;
}

.channelLogo {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    overflow: hidden;
}

.channelLogoImg {
    width: 100%;
    height: 100%;
}

.CV1nuggetsContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 3.5rem;
}

.channelUnlockButton {
    padding: 6px 12px;
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    /* font-size: 1rem; */
    font-weight: 600;
}

.channelUnlockButton:hover {
    background-color: #0E2C45;
    color: white;
}

.nuggetPricingBtn {
    color: black;
    background-color: #ffa41c;
    border: 1px solid #ffa41c;
    border-radius: 20px;
    margin: 5px 10px;
    padding: 8px 10px;
    cursor: pointer;
}

.nuggetPricingBtn:hover {
    background-color: #fa8900;
}

@media only screen and (max-width: 800px) {

    .channelApp {
        margin-top: 5vh;
    }

    .channelContainer {
        flex-direction: column;
    }

    .channelHeading {
        text-align: center;
    }

    .CV1nuggetsContainer {
        padding: 1rem;
        justify-content: center;
    }
}