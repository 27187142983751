.dot-and-copy-btn {
    background: transparent;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

/* 
.dot-and-copy-btn:hover {
    background: #0000002e;
} */

.custom-dropdown-item:hover {
    background: #ccc;
}

.response-div {
    cursor: pointer;
    padding: 7px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
    width: 20%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    background: rgb(204, 204, 204);
}

.response-div:hover {
    background: #b4afaf;
}