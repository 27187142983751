.ElementMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0rem 1rem;
  height: 80vh;
  overflow: hidden auto;
}

.ElementTextHeading {
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.ElementTextContainer {
  align-items: center;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  /* height: 36px; */
  position: relative;
  transition: all .15s;
  width: 100%;
  cursor: pointer;
  padding: 5px;
  margin: 2px;
}

.ElementTextContainer:hover {
  background-color: #ebeff2;
}

.ElementIcon {
  display: flex;
  height: 20px;
  margin-right: 2px;
  min-width: 30px;
  width: 20px;
}

.ElementText {
  color: black;
  display: flex;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  max-width: 100%;
  overflow-wrap: break-word;
  position: relative;
}

.ElementDividerLine {
  background-color: #dbdfe8;
  padding: 1px;
  margin-top: 1rem;
  width: 100%;
}

.sidebar_extension__searchbar {
  margin-top: 12px;
  padding: 0 16px;
  display: flex;
  position: relative;
  border: 1px solid #dbdfe8;
  border-radius: 5px;
}

.sidebar_extension__searchbar input {
  padding: 9px 10px;
  font-weight: 600;
  width: 100%;
}

.sidebar_extension__searchbar .search-icon {
  position: absolute;
  top: 3px;
  left: 0px;
}

.sidebar_extension__searchbar .search-close-icon {
  position: absolute;
  top: 11px;
  right: 6px;
  width: 0.8rem;
  cursor: pointer;
}

@media only screen and (max-width: 1275px) {
  .sidebar_extension__searchbar input {
    padding: 9px 10px;
    padding-left: 40px;
    color: rgba(60, 60, 67, 0.6);
    background: rgba(250, 250, 250, 0.93);
    font-size: 17px;
    border-radius: 20px;

  }

  .sidebar_extension__searchbar .search-icon {
    top: 5.5px;
  }

  .sidebar_extension__searchbar .search-close-icon {
    top: 12.5px;
  }
}