.confirm_replace_modal {
  background-color: rgba(23, 23, 23, 0.2);
  backdrop-filter: blur(5px);
}

.confirm_replace_modal .modal_basic {
  width: calc(584px - 89px);
  max-width: calc(584px - 89px);
  padding: 3rem 28px;
  padding-left: 35px;
}

.confirm_replace_modal .modal_basic .modal_header {
  text-align: center;
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 22px;
}

.confirm_replace_modal .modal_basic .modal_text {
  text-align: center;
  font-size: 16px;
  line-height: 140%;
  color: #474747;
  margin-bottom: 50px;
}

.confirm_replace_modal .modal_basic .action-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
}

.confirm_replace_modal .modal_basic .action-buttons button {
  padding: 8px 20px;
  height: 38px;
  font-family: "Mulish";
  font-weight: 600;
  transition: 0.2s;
}

.confirm_replace_modal .modal_basic .action-buttons .cancel-btn {
  margin-right: 1rem;
  color: #000;
  background-color: transparent;
}

.confirm_replace_modal .modal_basic .action-buttons .cancel-btn:hover {
  background-color: white;
}

@media only screen and (max-width: 1100px) {
  .confirm_replace_modal .modal_basic {
    align-self: auto;
  }
}

@media only screen and (max-width: 550px) {
  .confirm_replace_modal .modal_basic {
    margin-left: 10px;
    margin-right: 10px;
    padding: 28px 20px !important;
  }

  .confirm_replace_modal .modal_basic .modal_header {
    font-size: 1.3rem;
  }

  .confirm_replace_modal .modal_basic .modal_text {
    font-size: 1rem;
  }
}