.WorkflowChannelApp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.WorkflowChannelContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
    margin: 1rem;
    width: 35rem;
    border: 1px solid black;
    border-radius: 10px;
}

.WorkflowChannelRow {
    margin: 0.5rem;
    width: 100%;
}

.WorkflowChannelInput {
    background-color: transparent;
    color: black;
    font-size: 1rem;
    width: 95%;
    border: 1px solid black;
    border-radius: 5px;
    margin: 8px 0px;
    padding: 6px;
}

.WorkflowChannelSavebtn {
    border: 1px solid #3184c9;
    background-color: #3184c9;
    padding: 8px 20px;
    border-radius: 6px;
    margin: 1rem;
    color: white;
    cursor: pointer;
    font-size: 1rem;
}

.WorkflowChannelSavebtn:hover {
    background-color: transparent;
    border: 1px solid black;
    color: black;
}

.WorkflowChannelbtn {
    border: 1px solid black;
    background-color: transparent;
    padding: 8px 20px;
    border-radius: 6px;
    margin: 1rem;
    color: black;
    cursor: pointer;
    font-size: 1rem;
}

.WorkflowChannelbtn:hover {
    background-color: #0E2C45;
    color: white;
}

@media only screen and (max-width: 800px) {
    .WorkflowChannelContainer {
        width: 90%;
        margin: 0;
    }

    .WorkflowChannelInput {
        width: auto;
        min-width: auto;
    }
}