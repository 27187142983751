.PricingMain {
    margin-top: 70px;
}

.PR1flowcontainer {
    display: flex;
    margin: 2rem;
    justify-content: center;
}

.PR1flowcontainerForm {
    width: 40%;
    padding: 2rem;
    box-shadow: 0 3px 11px rgba(22, 27, 47, .12);
    border-radius: 2rem;
    text-align: start;
    background-color: white;
}


.PR1pricing_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 5vh;
}


.PR1pricingHeaderTab {
    position: relative;
    font-weight: 400;
    font-size: 20px;
    color: black;
    margin-bottom: 14px;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PR1pricingHeaderTabInner {
    width: auto;
    padding: 1rem 3rem;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 3px 11px rgba(22, 27, 47, .12);
}

.PR1pricing-header-label {
    margin: 8px 15px;
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 5px;
}

.pricingFreeContainerMain {
    background-color: white;
    width: 96%;
    margin: auto;
    padding: 2rem;
    border-radius: 40px;
    /* border: 10px solid white; */
}

/* .pricingFreeContainerMain:hover {
    border-color: #3184C9;
} */

.pricingFreeContainer {
    display: flex;
    justify-content: space-around;
}

.pricingFreeblock {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.pricingFreeblock div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.pricing-block-li {
    padding-left: 12rem;
}

@media only screen and (max-width: 600px) {

    .PR1pricing_container {
        align-items: center;
        justify-content: center;
    }

    .pricingFreeContainer {
        flex-direction: column;
    }

    .PR1flowcontainerForm {
        width: 95%;
    }

    .PricingMain {
        margin-top: 58px;
    }
}