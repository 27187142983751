.EM1modalMain {
    text-align: center;
    margin-top: 15vh;
    border-radius: 2vh;
    /* border: 2px solid #C00DE4; */
}

.EM1Continuebtn {
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    padding: 8px 20px;
    border-radius: 5px;
    margin: 2vh;
    color: black;
    cursor: pointer;
}

.EM1Continuebtn:hover {
    background-color: #0E2C45;
    border: 1px solid #0E2C45;
    color: white;
}

.modal-backdrop {
    z-index: -1;
}

.EM1embedType {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ecebeb;
    border-radius: 5px;
    padding: 0.6rem;
    cursor: pointer;
    margin: 10px;
    width: 3.5rem;
}

.EM1embedType span {
    padding-top: 5px;
    font-size: 0.6rem;
    line-height: 1.2;
}

@media (max-width: 800px) {}