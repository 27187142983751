.CU1App {
    text-align: center;
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #3184C9 -12.18%, #8f1b77 108.63%); */
    background-position: center;
    /* height: 100vh; */
    font-family: "Mulish";
    margin-top: 7.5vh;
}

.CU1template {
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px);
    height: 84vh;
    display: grid;
    align-items: center;
    align-content: center;
    z-index: -1;
    background-color: #F4F9FC;
}

.CU1container {
    background-color: white;
    color: black;
    /* border: 2px solid #858485; */
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    padding: 3rem 5rem;
}

.CU1textHeading {
    font-size: 3vh;
    padding: 3px;
}

.CU1text {
    font-size: 3vh;
    padding: 0.6rem;
}

@media only screen and (max-width: 800px) {
    .CU1container {
        width: 88%;
        padding: 0.5rem;
    }
}