.SP1App {
    text-align: center;
    background-color: white;
    height: 91.5vh;
    font-family: "Mulish";
    margin-top: 8.5vh;
    overflow: hidden auto;
}

.SP1template {
    padding: 1rem;
    width: 50%;
    margin: 1rem auto;
}

.SP1Container {
    display: flex;

}

.SP1InnerContainer {
    width: 11rem;
    height: 11rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #F4F9FC;
    padding: 1rem;
    margin: 1rem;
    cursor: pointer;
}

.SP1InnerContainer:hover {
    -ms-transform: scale(1.03);
    /* IE 9 */
    -webkit-transform: scale(1.03);
    /* Safari 3-8 */
    transform: scale(1.03);
}


/* Custom styles for the date picker input */
.custom-date-picker-container .react-datepicker-wrapper {
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #92909a;
    border-radius: 5px;
    width: 20rem;
}

.custom-date-picker-container .react-datepicker__input-container input {
    width: 100%;
}

@media only screen and (max-width: 800px) {

    .SP1App {
        margin-top: 7vh;
    }

    .SP1template {
        width: 100%;
    }

}