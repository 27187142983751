.M3modalBody {
    background: var(--ans-bgColor);
    color: var(--ans-fontColor);
    text-align: center;
    border-radius: 2vh;
    border: 1px solid var(--ans-hover-bgColor);
    margin-top: 25vh;
}

.M3correctimg {
    width: 40%;
    float: left;
    padding: 2vh;
    box-sizing: border-box;
}

.M3cong {
    font-size: 5vh;
}

.M3text {
    float: right;
    width: 60%;
}

.M3modalBody2 {
    background: #0000004f;
    border: none;
    color: white;
    text-align: center;
    margin-top: 25vh;
    border-radius: 2vh;
    border: 1px solid var(--ans-hover-bgColor);
}

.M3correctimg2 {
    width: 40%;
    margin: auto;
    box-sizing: border-box;
    display: block;
}

.M3cong2 {
    font-size: 8vh;
    font-weight: 900;
    text-shadow: 2px 2px white;
    display: inline-block;
}

.M3text2 {
    width: 100%;
}

.M3answerImg {
    width: 30vh;
    height: 20vh;
}

.M3mtfAnswer {
    display: flex;
    justify-content: space-around;
}

.M3mtfAnswerLeft {
    float: left;
    width: 48%;
    border: 1px solid gray;
}

.M3mtfAnswerRight {
    float: right;
    width: 48%;
    border: 1px solid gray;
}
.M3mtfAnswerLeftImg{
    width: 4vh;
    height: 4vh;
}
@media only screen and (max-width: 750px) {
    .M3correctimg2 {
        width: 50%;
    }
    .M3cong2 {
        font-size: 5vh;
    }
    .M3correctimg {
        width: 20vh;
        margin: auto;
        float: none;
        display: block;
    }

    .M3cong {
        font-size: 5vh;
        text-align: center;
    }

    .M3text {
        float: none;
        width: 100%;
    }

    .M3explain {
        text-align: center;
    }
}