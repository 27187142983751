.modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4242427d;
  cursor: default;
}

.content_wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  padding: 16px 50px;
  position: relative;
  width: 380px;
  height: 220px;

  background: #fbfbf7;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin: auto 20px;
}

.close_icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  padding: 10px 10px 5px 10px;
  border-radius: 50%;
}

.close_icon:hover {
  background-color: #86868626;
}

.content__heading {
  font-family: "Mulish";
  ;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;

  color: black;
}

.content__para {
  font-family: "Mulish";
  ;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #474747;
}

.content__button_wrapper {
  display: flex;
  gap: 30px;
}

.button_cancel {
  font-family: "Mulish";
  ;
  font-weight: 600;
  width: 71px;
  font-size: 14px;
  line-height: 19px;
  background-color: transparent;
  color: #000000;
  cursor: pointer;
}

.button_cancel:hover {
  border-radius: 5px;

  border: 1px solid black;
}

.button_confirm {
  width: 91px;
  height: 35px;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.7px;
  font-size: 14px;
  line-height: 19px;

  background: white;
  color: black;
  border: 1px solid black;
}

.button_confirm:hover {
  background-color: #0E2C45;
  color: white;
}

@media only screen and (max-width: 700px) {
  .content_wrapper {
    height: 210px;
    padding: 5px 30px;
    text-align: center;
  }

  .content__heading {
    font-size: 22px;
    line-height: normal;
  }

  .content__para {
    font-size: 15px;
    line-height: normal;
  }
}