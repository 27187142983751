.channelApp {
    text-align: center;
    background: white;
    background-position: center;
    min-height: 100vh;
    margin-top: 7vh;
    font-family: "Mulish";
}

.channeltemplate {
    color: black;
    min-height: 100vh;
    padding-top: 2rem;
}

.channelContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.channelHeading {
    margin-left: 1rem;
    text-align: left;
}

.channelLogo {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    overflow: hidden;
}

.channelLogoImg {
    width: 100%;
    height: 100%;
}

.channelShareButton {
    padding: 1rem 1rem;
    background-color: #3184C9;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    margin: 0.5rem auto;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
}

.channelShareButton:hover {
    border: 1px solid #3184C9;
}

.channelCreateButton {
    padding: 1rem 1rem;
    background-color: #0E2C45;
    color: white;
    border: 1px solid black;
    border-radius: 5px;
    margin: 0.5rem auto;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
}

.channelCreateButton:hover {
    background-color: white;
    color: black;
}

.channelNewCreateContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    flex-direction: column;
}

.channelTab {
    display: flex;
    justify-content: center;
    padding-top: 2px;
}

.channelTab label {
    padding: 0 2rem;
}

.selectedTabContainer {
    display: flex;
    margin-top: 16px;
    flex-wrap: wrap;
    margin-left: 40px;
    margin-bottom: 3rem;
}

.ChannelModalTitle {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    margin: 1vh 0;
}

.channelInputbox {
    margin: 1vh auto;
}

.channelInputbox input {
    border: 1px solid rgba(17, 17, 19, 0.2);
    background-color: #f4f6fc;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 10px;
    width: 100%;
    transition: 0.2s;
    font-family: Nunito_Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #6b6f74;
}

@media only screen and (max-width: 800px) {

    .channelApp {
        margin-top: 5vh;
    }

    .channelContainer {
        flex-direction: column;
    }

    .channelHeading {
        text-align: center;
    }

    .channelTab label {
        padding: 0 1rem;
    }

    .selectedTabContainer {
        justify-content: center;
        margin-left: 0px;

    }

}