.notfoundApp {
    text-align: center;
    height: 100vh;
    font-family: Nunito_Sans;
}

.notfoundAppTemplate {
    align-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px);
    display: grid;
    height: 100vh;
    z-index: -1;
}

.notfoundImage {
    width: 25rem;
    margin: auto;
    filter: drop-shadow(4px 4px 4px #3b3b3b);
}

.notfoundBtn {
    width: 8rem;
    color: white;
    background-color: #3184C9;
    /* border: 1px solid gray; */
    border-radius: 6px;
    padding: 10px;
    margin: 3rem auto;
    cursor: pointer;
}

.notfoundBtn:hover {
    background-color: #7a108f;
}


@media only screen and (max-width: 800px) {
    .notfoundImage {
        width: 95%;
    }
}