.SlackIntegrationButton {
    padding: 10px 18px;
    background-color: black;
    color: white;
    cursor: pointer;
}

.gmail_textbox {
    border: 1px solid #92909a;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    font-size: 16px;
    line-height: 22px;
    width: 20rem;
}