.Viewer_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


.swiper_container {
    display: flex !important;
    align-items: center;
    justify-content: space-around;
    position: unset !important;
    height: 100vh;
    margin: auto;
}

.swiper__navigation_icon {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: #76767685;
    padding: 7px;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}

.swiper__navigation_icon.right {
    position: absolute;
    right: 14px;
    display: flex;
}

.swiper__navigation_icon.left {
    position: absolute;
    display: flex;
    left: 10px;
}

/* ////////////////////////// top navbar */
.viewertopnav {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 9999;
}

.viewerinsidetopnav {
    display: flex;
    align-items: center;
    width: 33vw;
}

.viewerlogoImg {
    width: 14vw;
    height: 8vh;
    border-radius: 10px;
    object-fit: contain;
    padding: 2px;
}

.viewertimerbox {
    border-radius: 15px;
    margin: auto;
    padding: 2px 1.2rem;
    color: blue;
    background-color: white;
    z-index: 1;
    text-align: center;
}

.viewertimer {
    font: italic normal 800 2rem/2rem Montserrat;
}

.M1Progressbox {
    width: 85vw;
    position: relative;
    margin: 0 auto;
    min-height: 4vh;
}

.M1icon {
    background: var(--ans-hover-bgColor);
    color: var(--ans-fontColor);
    border-radius: 12px;
    padding: 1px 0px;
    cursor: pointer;
    margin: 0vh 1vh;
    display: flex;
    align-items: center;
}