.CFS1modal {
    background-color: rgb(255, 255, 255);
    width: 950px;
    height: 32rem;
    border-radius: 8px;
    overflow: auto;
    text-align: center;
}

.CFS1MainContainer {
    padding: 1rem;
}

.CFS1startscratch {
    cursor: pointer;
    color: #979dc6;
    background-color: #F4F9FC;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 110%;
    height: 15rem;
    /* padding: 32px 16px; */
    transition: all .2s linear;
    display: flex;
}

.CFS1templateContainer {
    /* display: flex; */
    align-items: stretch;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin-top: 5rem;
}

@media (min-width: 768px) {
    .CFS1templateContainer {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 767px) {
    .CFS1templateContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 480px) {
    .CFS1templateContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

.CFS1template {
    position: relative;
    overflow: hidden;
    width: 28%;
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 0px 20px;
}

.CFS1templateBlock {
    position: relative;
    width: 28%;
    /* margin-top: 32px; */
    padding: 0px 20px;
    text-align: center;
    margin: 25px 15px;
}


.CFS1templateImage {
    cursor: pointer;
    width: 100%;
    height: 10rem;
    position: relative;
    border: 1px solid rgb(200, 206, 237);
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.CFS1templatePreviewContainer {
    position: absolute;
    background-color: rgba(9, 17, 65, 0.8);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.CFS1templatePreview {
    position: absolute;
    background-color: rgba(10, 21, 81, 0.75);
    border-radius: 40px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    z-index: 3;
    padding: 11px 16px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    column-gap: 10px;
    opacity: 1;
    transition: all 0.3s linear 0s;
}

.CFS1templateImage img {
    width: 100%;
    height: auto;
}

.CFS1templateHeading {
    position: relative;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    min-height: 24px;
    margin: 14px 0px 10px;
    padding: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 800px) {
    .CFS1template {
        width: 100%;
    }

    .CFS1templateBlock {
        width: 100%;
    }

    .CFS1startscratch {
        height: 12rem;
    }
}

@media only screen and (max-width: 1100px) {
    .CFS1close {
        display: flex !important;
    }
}