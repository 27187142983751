.leaderboardPage {
    /* background: url("../../assets/Images/mcqWithTimer.svg");*/
    background-size: cover;
    background-color: #0B1350;
    color: white;
    /* min-height: 100vh; */
}

.ledearboardInner {
    backdrop-filter: blur(5px);
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
}

.ledearboardInner1 {
    width: 60%;
    margin: auto;
}

.SL1icons {
    text-align: end;
    padding: 2vh 2vh;
}

.SL1icon {
    cursor: pointer;
}

.SL1Img {
    width: 10rem;
    height: 16vh;
    margin: 2vh auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1vh;
}

.SL1showImg {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: contain;
}

.SL1title {
    font-size: 3rem;
    margin: 2vh auto;
    font-weight: 600;
    white-space: pre-wrap;
}

.SL1description {
    font-size: 1.5rem;
    margin: 4vh auto;
    white-space: pre-wrap;
}

.SL1couponcode {
    background-color: #C00DE4;
    color: white;
    padding: 1rem;
    width: fit-content;
}

.SL1circle_buttons {
    padding: 4vh;
}

.SL1iconCircle {
    border: 1px solid #C00DE4;
    border-radius: 50%;
    position: relative;
    padding: 4px;
    font-size: 3vh;
    margin: 6px;
    height: 6vh;
    width: 6vh;
    color: #77FDDD;
    background: #101957;
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
}

.SL1iconCircle:hover {
    -ms-transform: scale(1.05);
    /* IE 9 */
    -webkit-transform: scale(1.05);
    /* Safari 3-8 */
    transform: scale(1.05);
}

.SL1button {
    padding: 16px 30px;
    background-color: #0B1350;
    border: 1px solid #B117D0;
    border-radius: 5px;
    color: white;
    margin: 2vh;
    cursor: pointer;
}

.SL1button:hover {
    background-color: #B117D0;
    -ms-transform: scale(1.05);
    /* IE 9 */
    -webkit-transform: scale(1.05);
    /* Safari 3-8 */
    transform: scale(1.05);
}

.SL1Continuebtn {
    border: 1px solid black;
    background-color: white;
    padding: 8px 20px;
    border-radius: 6px;
    margin: 2vh;
    color: black;
    cursor: pointer;
}

.SL1Continuebtn:hover {
    -ms-transform: scale(1.05);
    /* IE 9 */
    -webkit-transform: scale(1.05);
    /* Safari 3-8 */
    transform: scale(1.05);
}

@media only screen and (max-width: 800px) {

    /* .SL1iconCircle {
        font-size: 4vh;
        margin: 8px 20px;
        height: 8vh;
        width: 8vh;
    } */
    .SL1Continuebtn {
        padding: 15px 50px;
        font-size: 2vh;
    }

    .leaderboardPage {
        background-size: auto;
    }

    .ledearboardInner1 {
        width: 90%;
    }

    .SL1title {
        font-size: 2rem;
    }
}