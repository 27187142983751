.AIDPN1App {
    text-align: center;
    background-color: white;
    background-size: contain;
    text-align: center;
    overflow-x: hidden;
    color: black;
    font-size: 1.1rem;
}

.AIDPN1template {
    backdrop-filter: blur(15px);
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.AIDPN1leftContainer {
    width: 15%;
    padding: 1rem;
    background-color: #0E2C45;
    color: white;
    position: relative;
}

.AIDPN1rightContainer {
    width: 85%;
    /* height: 100vh; */
    height: auto;
    overflow: auto;
    padding: 3rem;
}

.AIDPN1tabName {
    padding: 12px 6px;
    margin: 5px;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
}

.AIDPN1tabName:hover {
    font-weight: 600;
}

.AIDPN1container1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    line-height: 25px;
    padding: 2rem;
    background-color: white;
    border: 1px solid #e5e7eb;
}

.AIDPN1container1 div {
    padding: 10px 0px;
}

.AIDPN1container2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: flex-start; */
    text-align: left;
    line-height: 25px;
    padding: 1rem;
    background-color: white;
    border: 1px solid #e5e7eb;
    margin-bottom: 2rem;
}

.AIDPN1question-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.AIDPN1question-item {
    padding: 5px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    min-height: 3.2rem;
}

.AIDPN1question-item:last-child {
    border-bottom: none;
}

.AIDPN1icon {
    font-size: 18px;
    color: #3184c9;
    cursor: pointer;
}

.AIDPN1icon .fa-pencil {
    color: rgba(119, 119, 109, 0.42);
}

.AIDPN1icon .fa-pencil:hover {
    color: #0E2C45;
}

.AIDPN1icon:hover {
    color: #0E2C45;
}

.AIDPN1reportContainer {
    border: 1px solid #e5e7eb;
    border-radius: 15px;
    padding: 10px;
    margin: 1rem;
    cursor: pointer;
}

.AIDPN1reportContainer:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.AIDPN1reportContainerItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}

.AIDPN1reportAnalyticsContainer {
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    padding: 10px;
    margin: 1rem 1rem 1rem 0rem;
    width: 10rem;
}

.AIDPN1analytics-item-container {
    padding: 15px;
    margin: 1rem 0rem;
    border: 1px solid #ddd;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.AIDPN1analytics-item {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.AIDPN1DashboardContainerItem {
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    padding: 1rem;
    margin: 1rem 0rem;
    width: 95%;
}


.AIDPN1button {
    border: 1px solid #3184c9;
    background-color: #3184c9;
    color: white;
    padding: 12px 15px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
}

.AIDPN1button:hover {
    border: 1px solid #0E2C45;
    background-color: #0E2C45;
    color: white;
}

.AIDPN1reportAnalyticsContainer-top {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    text-align: center;
}

.open-logout {
    cursor: pointer;
    background: #0e2c45;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 6px;
    color: white;
    width: 15%;
    font-size: 20px;
    position: fixed;
    border-radius: 5px;
    text-align: left;
    transition: transform 0.3s ease-in;
    transform: translateY(-8rem);
    z-index: 10000;
    bottom: 0;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
    left: 10px;

}

@media (max-width: 768px) {
    .open-logout {
        /* top: -100px; */
        width: 100%;
        transform: translateY(-50px);
    }
}

.open-logout ul {
    /* padding: 5px; */
}

.open-logout ul li {
    padding: 6px 2px 6px 5px;
    /* margin: 5px; */
    font-size: 15px;
    border-radius: 5px;
}

.open-logout ul li:hover {
    background-color: rgba(186, 193, 196, 0.7);

}

/* Notification bar styles */
.coming-soon {
    position: fixed;
    bottom: 0;
    padding: 5px;
    background: #3184c9;
    color: white;
    z-index: 9999;
    width: 75.8vw;
    text-align: center;
    font-size: 1rem;
    /* Default font size */
}

.AIDPN1button-publish {
    border: 1px solid #bbbdbe;
    background-color: transparent;
    color: black;
    padding: 12px 15px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
}

.AIDPN1button-publish:hover {
    border: 1px solid #0E2C45;
    background-color: #0E2C45;
    color: white;
}

/* Adjust font size for smaller screens */
@media (max-width: 768px) {
    .coming-soon {
        font-size: 0.8rem;
        /* Smaller font size for screens less than 768px */
    }
}

@media (max-width: 480px) {
    .coming-soon {
        font-size: 0.7rem;
        /* Even smaller font size for very small screens */
    }
}

/* Hide sidebar on small screens by default */
@media (max-width: 768px) {
    .AIDPN1leftContainer {
        display: none;
    }

    .AIDPN1leftContainer.collapsed {
        display: block;
        position: fixed;
        left: 0;
        width: 250px;
        /* Adjust sidebar width */
        height: 95%;
        background-color: #0e2c45;
        /* Customize as needed */
        z-index: 1000;
        padding: 20px;
    }

    /* Sidebar toggle button (visible only on small screens) */
    .sidebar-toggle {
        display: block;
        position: fixed;
        top: 10px;
        left: 10px;
        cursor: pointer;
        z-index: 1001;
        color: #fff;
        font-size: 1.5rem;
    }

}

/* On larger screens, sidebar should be visible */
@media (min-width: 769px) {
    .AIDPN1leftContainer {
        display: block;
    }

    .sidebar-toggle {
        display: none;
    }
}

/* Media query for small screens */
@media (max-width: 768px) {

    .AIDPN1reportAnalyticsContainer-top {
        display: flex;
        flex-direction: column;
    }

    .AIDPN1reportContainerItem,
    .AIDPN1reportAnalyticsContainer-all,
    .AIDPN1analytics-item {
        display: flex;
        flex-direction: column;
        margin: 10px;
        align-items: flex-start;
    }


}