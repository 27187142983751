.AS1effectContainer {
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.AS1effectContainer label {
    padding: 2px;
    cursor: pointer;
}

.AS1effectContainer label input {
    margin-right: 5px;
}

.ApplyAllBtn {
    position: fixed;
    bottom: 0;
    width: 20rem;
    margin: 1rem auto;
    padding: 10px 1rem;
    border-radius: 5px;
    background-color: black;
    color: white;
    cursor: pointer;
}

.ApplyAllBtn:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
}

.baseImage_container {
    display: flex;
    position: relative;
    /* margin-top: 1rem; */
}

.baseImage_container .edit-duonut-icon {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 32px;
}

.baseImage_container img {
    /* margin-bottom: 8px; */
    width: 12.5rem;
    /* max-width: 700px; */
    /* max-height: 150px; */
    object-fit: contain;
}