/* form modal */
.form_modal {
    background-color: rgb(255, 255, 255);
    width: 880px;
    border-radius: 8px;
    overflow: hidden;
}

.form_modal .col-lg-6 {
    padding: 0;
}

.form_modal .form-column {
    position: relative;
}

/* form img column */
.form-img-container {
    display: flex;
}

.form_modal .form-img {
    width: 100%;
    max-width: 414px;
    min-height: 95vh;
}

/* form column */
.demo_form_modal__form {
    margin-top: 46px;
    padding: 0 47px 0 20px;
}

/* HEADER */
.demo_form_modal__header {
    position: relative;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
    color: black;
    text-align: center;
    margin-bottom: 1rem;
}

.demo_form_modal__input_container {
    margin-bottom: 1rem;
}

.demo_form_modal__input_container input {
    border: 1px solid rgba(17, 17, 19, 0.2);
    background-color: #f4f6fc;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 16px;
    width: 100%;
    transition: 0.2s;
    font-family: Nunito_Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: black;

}

.demo_form_modal__input_container .cardDetails {
    border: 1px solid rgba(17, 17, 19, 0.2);
    background-color: #f4f6fc;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 16px;
    width: 100%;
    transition: 0.2s;
    font-family: Nunito_Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: black;

}


.demo_form_modal__input_container textarea {
    border: 1px solid rgba(17, 17, 19, 0.2);
    background-color: #f4f6fc;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 16px;
    width: 100%;
    transition: 0.2s;
    font-family: Nunito_Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: black;

}

.demo_form_modal__input_container select {
    border: 1px solid rgba(17, 17, 19, 0.2);
    background-color: #f4f6fc;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 16px;
    width: 100%;
    transition: 0.2s;
    font-family: Nunito_Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: black;
}

.demo_form_modal__input_container input:focus {
    border: 1px solid #111113;
    outline: 3px solid rgba(17, 17, 19, 0.2);
}

.ScheduleBtn {
    padding: 15px;
    background-color: white;
    border-radius: 0px;
    border: 2px solid black;
    color: black;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}

.ScheduleBtn:hover {
    background-color: black;
    color: white;
}

.demo_modal_back_btn {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .demo_modal_back_btn {
        display: inline-block !important;
        justify-content: center;
        align-items: center;
    }
}