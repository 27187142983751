.M1modalBody{
    background: var(--ans-bgColor);
    color: var(--ans-fontColor);
    text-align: center;
    margin-top: 30vh;
    border-radius: 2vh;
    border: 1px solid var(--ans-hover-bgColor);
}

.M1correctimg{
    width: 40%;
    float: left;
    padding: 2vh;
    box-sizing: border-box;
}
.M1text{
    float: right;
    width: 60%;
}
.M1cong{
    font-size: 5vh;
    margin-top: 6vh;
    display: inline-block;
}
.M1msg{
    font-size: 3vh;
}

.M1modalBody2{
    background: var(--ans-bgColor);
    color: var(--ans-fontColor);
    text-align: center;
    margin-top: 35vh;
    border-radius: 2vh;
    border: 1px solid var(--ans-hover-bgColor);
}
.M1text2{
    width: 100%;
}
.M1correctimg2{
    width: 30%;
    margin: auto;
    box-sizing: border-box;
    display: block;
}
.M1cong2{
    font-size: 5vh;
    display: inline-block;
}
.M1msg2{
    font-size: 3vh;
}

.M1modalBody3{
    background: #0000004f;
    border: none;
    text-shadow: 2px 2px white;
    color: white;
    text-align: center;
    margin-top: 30vh;
    border-radius: 2vh;
    border: 1px solid var(--ans-hover-bgColor);
}
.M1cong3{
    font-size: 8vh;
    font-weight: 900;
    display: inline-block;
}

.M1modalBody4{
    background: var(--ans-bgColor);
    color: var(--ans-fontColor);
    text-align: center;
    margin-top: 35vh;
    border-radius: 2vh;
    border: 1px solid var(--ans-hover-bgColor);
}
.M1text4{
    width: 100%;
}
.M1correctimg4{
    width: 25%;
    margin: auto;
    box-sizing: border-box;
    display: block;
}
.M1cong4{
    font-size: 3vh;
    margin-top: 2vh;
    display: inline-block;
}

@media only screen and (max-width: 750px) {
    .M1correctimg2{
        width: 40%;
    }
    .M1correctimg{
        width: 20vh;
        margin: auto;
        float: none;
        display: block;
    }
    .M1text{
        float: none;
        width: 100%;
    }
    .M1cong{
        font-size: 5vh;
        text-align: center;
        margin-top: 0vh;
    }
    .M1msg{
        font-size: 3vh;
        text-align: center;
    }
    .M1explain{
        text-align: center;
    }
}