.OB1App {
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #3184C9 -12.18%, #8f1b77 108.63%); */
    text-align: center;
    font-family: "Mulish";
    margin-top: 8vh;
    overflow-x: hidden;
    min-height: 95vh;
    color: black;
}

.OB1useTypeTemplate {
    display: inline-block;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    padding: 2vh;
}

.OB1container {
    display: flex;
    margin: 2vh auto;
}

.OB1Icon {
    width: 20px;
    position: absolute;
    right: 0;
    padding-right: 1rem;
}

.OB1Image {
    width: 10vw;
}

.OB1useType {
    /* background-color: rgba(219, 217, 217, 1); */
    color: black;
    border: 1px solid black;
    border-radius: 10px;
    margin: 2vh;
    padding: 2vh;
    width: 16vw;
    position: relative;
    cursor: pointer;
}

.OB1useType:hover {
    -ms-transform: scale(1.05);
    /* IE 9 */
    -webkit-transform: scale(1.05);
    /* Safari 3-8 */
    transform: scale(1.05);
}

.OB1button {
    padding: 2vh 4vh;
    /* border: 1px solid #3184c9; */
    background-color: #3184c9;
    color: white;
    border-radius: 5px;
    margin: 2vh;
    cursor: pointer;

}

.OB1button:hover {
    /* border: 1px solid #0E2C45; */
    background-color: #0E2C45;
    color: white;
    -ms-transform: scale(1.05);
    /* IE 9 */
    -webkit-transform: scale(1.05);
    /* Safari 3-8 */
    transform: scale(1.05);
}

.OB1TeamContainer {
    display: flex;
    margin: 2vh 2vw;
    flex-direction: column;
    width: 35vw;
}

.OB1input_container {
    margin-bottom: 1rem;
    text-align: left;
}

.OB1input_container_select {
    border: 1px solid rgba(149, 149, 161, 0.2);
    background-color: white;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 6px;
    width: 100%;
    transition: 0.2s;
    font-family: Nunito_Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: black;
}

.form-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin: 20px 0px;
    width: 40vw;
}

.form-group label {
    width: 30%;
    text-align: left;
}

.form-group input {
    background: transparent;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    width: 80%;
}

.highlight {
    border: 2px solid red;
    background-color: #ffe6e6;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

@media only screen and (max-width: 600px) {
    .OB1App {
        margin-top: 5vh;
    }

    .OB1container {
        display: block;
    }

    .OB1Image {
        width: 35vw;
    }

    .OB1useType {
        width: 80%;
    }

    .OB1TeamContainer {
        width: 90%;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .form-group label,
    .form-group input {
        width: 100%;
    }
}