.IGP1App {
    text-align: center;
    background-color: white;
    height: 91.5vh;
    font-family: "Mulish";
    margin-top: 8.5vh;
    overflow: hidden auto;
}

.IGP1template {
    padding: 1rem;
    width: 50%;
    margin: 1rem auto;
}

.IGP1Container {
    display: flex;
    flex-wrap: wrap;
}

.IGP1InnerContainer {
    width: 11rem;
    height: 11rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #F4F9FC;
    padding: 1rem;
    margin: 8px;
    cursor: pointer;
}

.IGP1InnerContainer:hover {
    -ms-transform: scale(1.03);
    /* IE 9 */
    -webkit-transform: scale(1.03);
    /* Safari 3-8 */
    transform: scale(1.03);
}

@media only screen and (max-width: 800px) {

    .IGP1App {
        margin-top: 7vh;
    }

    .IGP1template {
        width: 100%;
    }

}