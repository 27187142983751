.SitesChannelApp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* height: 100vh; */
    width: 36rem;
    margin: auto;
    margin-top: 12vh;
}

.SitesChannelApp1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75vh;
    width: 36rem;
    margin: auto;
    margin-top: 12vh;
}

.SitesChannelContainer {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: flex-start;
    padding: 5px;
    margin: 10px;
    width: 35rem;
    border: 1px solid black;
    border-radius: 10px;
}

.SitesChannelRow {
    margin: 0.5rem;
    width: 100%;
}

.SitesChannelSavebtn {
    border: 1px solid #3184c9;
    background-color: #3184c9;
    padding: 8px 20px;
    border-radius: 6px;
    margin: 1rem;
    color: white;
    cursor: pointer;
    font-size: 1rem;
}

.SitesChannelSavebtn:hover {
    background-color: transparent;
    border: 1px solid black;
    color: black;
}

.SitesChannelbtn {
    border: 1px solid black;
    background-color: transparent;
    padding: 8px 20px;
    border-radius: 6px;
    margin: 1rem;
    color: black;
    cursor: pointer;
    font-size: 1rem;
}

.SitesChannelbtn:hover {
    background-color: #0E2C45;
    color: white;
}

.SitesChanneldelbtn {
    border: 1px solid red;
    background-color: transparent;
    padding: 8px 20px;
    border-radius: 6px;
    margin: 1rem;
    color: black;
    cursor: pointer;
    font-size: 1rem;
}

.SitesChanneldelbtn:hover {
    border: 1px solid black;
    color: black;
}

@media only screen and (max-width: 800px) {
    .SitesChannelContainer {
        width: 90%;
        margin: 0;
    }

}

.SitesChannelSkipbtn{
    /* border: 1px solid red; */
    background-color: transparent;
    padding: 6px 10px;
    /* border-radius: 6px; */
    /* margin: 1rem; */
    color: black;
    cursor: pointer;
    font-size: 1rem;
}
