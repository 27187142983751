.credit_wrapper {
    /* padding-top: 25px; */
    width: 100%;
    margin: auto;
    padding: 30px;
}

.credit_wrapper_heading {
    line-height: 38px;
    text-align: center;
    margin-bottom: 30px;
}

.credit_right_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem;
    background: #f4f6fc;
    border-radius: 10px;
    margin: 3rem;
}

/* Modal Overlay */
.credit-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal Container */
.credit-container {
    position: relative;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: "70vw";
}

/* Close Button */
.credit-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
}