.ASPN1App {
    text-align: center;
    background-position: center;
    /* height: 100vh; */
    font-family: "Mulish";
    /* margin-top: 7.5vh; */
    background-color: white;
}

.ASPN1template {
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px);
    width: 100%;
    margin: 0;
    min-height: 90vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.ASPN1container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}

.ASPN1container-main {
    margin: 0 auto;
}

.Ai-input_box {
    border: 1px solid rgba(149, 149, 161, 0.2);
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 5px 0px 0px 5px;
    width: 100%;
    max-width: 30rem;
    font-weight: 400;
    font-size: 16px;
    color: black;
    /* margin: 1rem; */
    padding: 10px;
    display: grid;
}

.Ai-input_box input {
    margin: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.sidebar {
    width: 250px;
    background-color: #0E2C45;
    color: white;
    height: 98vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.3s;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 50px;
}

.sidebar-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    margin: 0;
}

.sidebar-content button {
    background-color: transparent;
    /* border: 1px solid rgb(27, 75, 157); */
    border-radius: 5px;
    width: 50px;
}

.sidebar-content button i {
    text-align: center;
}

.sidebar-icons {
    position: absolute;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.sidebar-logo {
    max-width: 100px;
    height: auto;
}

.sidebar-icons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: auto;
}

.sidebar.closed {
    width: 95px;
}

.sidebar-logo {
    width: 100px;
    transition: width 0.3s;
}

.sidebar.closed .sidebar-logo {
    width: 50px;
}

.sidebar-icons {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sidebar i {
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.toggle-icon {
    color: rgb(27, 75, 157);
    font-size: 24px;
    cursor: pointer;
}

.sidebar-icons div {
    padding: 10px;
    text-align: center;
    max-width: 180px;
    word-wrap: break-word;
    /* Ensures text wraps when it reaches the container's edge */
}

.sidebar-icons p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 1.4;
}

.GPT-Input img {
    opacity: 0.7;
}

.GPT-Input img:hover {
    opacity: 1;
    /* border-radius: 0 5px 5px 0; */
    /* border: 1px solid rgba(11, 19, 80, 1);  */
}

.ASPN1container-logo-head {
    margin-bottom: 50px;
}

.ASPN1container-logo {
    width: 28%;

}

.loading-text {
    font-size: 26px;
    text-align: center;
}

.start_page_btn {
    font-family: "Mulish";
    font-weight: 600;
    background-color: #3184C9;
    border: 1px solid white;
    color: white;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    min-width: 5rem;
    margin: 0.5rem;
}

.start_page_btn:hover {
    color: white;
    background-color: #0E2C45;
}

.ASPN1AiScanType {
    border: 1px solid #3184C9;
    padding: 1rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    width: 5rem;
    cursor: pointer;
    color: #3184C9;
}

.ASPN1AiScanType:hover {
    color: #0E2C45;
    border: 1px solid #0E2C45;
}

.CFS1startscratch:hover {
    box-shadow: 1px 1px 5px 1px #3184C9;
}

.box_shadow {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

/* Slide Up Transition (Div 1 Going Out) */
/* Slide Up */
.slide-up-enter {
    transform: translateY(100%);
    opacity: 0;
}

.slide-up-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: transform 200ms ease-in, opacity 200ms ease-in;
}

.slide-up-exit {
    transform: translateY(0);
    opacity: 1;
}

.slide-up-exit-active {
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 200ms ease-out, opacity 200ms ease-out;
}

/* Slide Down */
.slide-down-enter {
    transform: translateY(-100%);
    opacity: 0;
}

.slide-down-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: transform 200ms ease-in, opacity 200ms ease-in;
}

.slide-down-exit {
    transform: translateY(0);
    opacity: 1;
}

.slide-down-exit-active {
    transform: translateY(100%);
    opacity: 0;
    transition: transform 200ms ease-out, opacity 200ms ease-out;
}

/* General Container Styling */
.transition-container {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
}

@media only screen and (max-width: 800px) {
    .ASPN1container {
        margin: 30px;
    }

    .loading-text {
        font-size: 20px;
        margin: 20px;
    }

    .ASPN1container-logo {
        width: 30%;
        height: 40%;
    }
}

.ASPN1template {
    scrollbar-width: thin;
    /* Firefox */
}

.ASPN1template::-webkit-scrollbar {
    width: 8px;
    /* Width of scrollbar */
}

.ASPN1template::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Scrollbar thumb color */
    border-radius: 4px;
}

.ASPN1template::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Hover color for thumb */
}