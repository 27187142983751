.usersettingContainer {
    height: 90vh;
    padding: 2rem;
    position: relative;
}

.usersettingDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.usersettingNewbtn {
    border: 1px solid #3184C9;
    background-color: #3184C9;
    padding: 8px 20px;
    border-radius: 6px;
    margin: 2vh;
    color: white;
    cursor: pointer;
}

.usersettingNewbtn:hover {
    background-color: transparent;
    color: black;
}

.userSettingTable {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem auto;
    max-height: 55vh;
    overflow-y: auto;
}

.userSettingTable table {
    border: 2px solid black;
    width: 800px;
}

.userSettingTable th {
    padding: 1vh;
    background: #3184C9;
    color: white;
    text-align: center;
}

.userSettingTable tr {
    border: 1px solid black;
    padding: 2px;
}

.userSettingTable td {
    text-align: center;
    /* background: aliceblue; */
    padding: 1.5vh;
}

.userSettingTableAction {
    display: flex;
    align-items: center;
    justify-content: center;
}

.userSettingTableActionBtn {
    background-color: #3184C9;
    color: white;
    border-radius: 50%;
    font-size: 1rem;
    height: 2rem;
    display: flex;
    width: 2rem;
    margin: 0px 5px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.userSettingTableActionBtn:hover {
    background-color: #3184C9;
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
}

.userSettingmodalMain {
    text-align: center;
    margin-top: 22vh;
    border-radius: 2vh;
    /* border: 2px solid #C00DE4; */
}

.userSettingModalTitle {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2vh 0vh;
    line-height: 2rem;
}

.usersettingModalSubmitbtn {
    border: 1px solid #3184C9;
    /* background-color: #3184C9; */
    padding: 8px 20px;
    border-radius: 6px;
    margin: 2vh;
    color: black;
    cursor: pointer;
}

.usersettingModalSubmitbtn:hover {
    background-color: transparent;

}

.usersettingInputbox {
    margin: 1vh auto;
}

.usersettingDropdown select {
    border: 1px solid rgba(17, 17, 19, 0.2);
    background-color: #f4f6fc;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 10px;
    width: 100%;
    transition: 0.2s;
    font-family: Nunito_Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #6b6f74;
}

.usersettingInputbox input {
    border: 1px solid rgba(17, 17, 19, 0.2);
    background-color: #f4f6fc;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 10px;
    width: 100%;
    transition: 0.2s;
    font-family: Nunito_Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #6b6f74;
}

@media only screen and (max-width: 700px) {

    .usersettingDetails {
        flex-direction: column;
    }
}