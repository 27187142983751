.AI1App {
    text-align: center;
    background-position: center;
    font-family: Nunito_Sans;
    margin-top: 8.5vh;
    background: #f4f5f8;
    overflow: hidden;
}

.AI1template {
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px);
    height: 91.5vh;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
}

.AI1headingContainer {
    padding: 3rem 10rem;
}

.AI1heading {
    padding: 1rem 0rem;
    font-size: 3rem;
}

.AI1container {
    color: black;
    /* border: 2px solid #B117D0; */
    border-radius: 10px;
    padding: 1rem 2rem 2rem 2rem;
    background-color: white;
}

.AI1btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 1rem;
}

.AI1ExapmpleQue {
    background-color: white;
    border-radius: 15px;
    padding: 0.7rem 1rem;
    margin: 1rem;
    cursor: pointer;
}

.AI1templateContainer {
    padding: 2rem 8rem;
}

.AI1formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem;
    width: 100%;
    background-color: white;
}

.AI1inputbox {
    width: 100%;
    padding-left: 1rem;
}

.GPT-input_box {
    border: 1px solid rgba(149, 149, 161, 0.2);
    background-color: #f4f6fc;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 6px;
    width: 100%;
    transition: 0.2s;
    font-family: Nunito_Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: black;
}

.GPT-input_container {
    display: flex;
    bottom: 1rem;
    position: absolute;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 0px 5px;
}

.GPT-submitBtn {
    padding: 1.5vh 4vh;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    color: black;
    margin: 0px 1vh;
    cursor: pointer;
    font-weight: 600;
}

.GPT-submitBtn:hover {
    background-color: #0E2C45;
    color: white;

    -ms-transform: scale(1.05);
    /* IE 9 */
    -webkit-transform: scale(1.05);
    /* Safari 3-8 */
    transform: scale(1.05);
}


@media only screen and (max-width: 800px) {

    .AI1App {
        margin-top: 7vh;
    }

    .AI1headingContainer {
        padding: 2rem 1rem;
    }

    .AI1heading {
        font-size: 2rem;
    }

    .AI1btnContainer {
        flex-direction: column;
    }

    .AI1btnContainer div {
        padding: 0.4rem 0rem;
    }

    .AI1templateContainer {
        padding: 3rem 0rem;
    }

    .AI1formContainer {
        flex-direction: column;
    }

    .AI1formContainer div {
        padding: 0.4rem 0rem;
    }

    .AI1inputbox {
        padding-left: 0rem;
    }

}