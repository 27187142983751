.UCI1App {
    text-align: center;
    background-color: white;
    font-family: "Mulish";
    margin-top: 8.5vh;
}

.UCI1template {
    width: 70%;
    margin: auto;
    text-align: left;
}

.UCI1template div {
    line-height: 30px;
}

.UCI1content a {
    display: block;
    color: black;
}

.UCI1content a:hover {
    text-decoration: underline;
}

.UCI1section-1 {
    width: 100%;
    padding: 2rem 0rem;
    display: flex;
    justify-content: center;
}


.UCI1section-1-image {
    width: 60%;
    height: 100%;
    object-fit: contain;
    border: 1px solid rgb(231, 231, 231);
}

.UCI1tagsection a {
    color: black;
    border: 1px solid black;
    border-radius: 1.5rem;
    padding: 8px 15px;
    margin-right: 1rem;
}

.UCI1Button {
    padding: 16px 40px;
    background-color: #3184c9;
    color: white;
    border: 1px solid #3184c9;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.2rem;
}

/* .UCI1Button:hover {
    border: 1px solid #3184c9;
} */
.UCI1template_container {
    border: 1px solid #dbdfe8;
    border-radius: 15px;
    padding: 1rem;
    margin: 0.5rem;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {

    .UCI1App {
        margin-top: 7vh;
    }

    .UCI1template {
        width: 90%;
    }

    .UCI1section-1-image {
        width: 100%;
    }

}