.AIDVP1App {
    text-align: center;
    font-family: "Mulish";
}

.AIDVP1template {
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
}

.AIDVP1container {
    background-color: white;
    color: black;
    /* border: 2px solid #858485; */
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    width: fit-content;
    padding: 1.5rem 1rem;
    margin: 2rem 0rem 2rem 0rem;
    max-width: 48rem;
}

.AIDVP1Aicontainer {
    background-color: white;
    color: black;
    border: 1px solid #cac7ca;
    border-radius: 20px;
    width: 80%;
    padding: 1rem;
    margin: 2rem 0rem 3rem 0rem;
}

.AIDVP1Ai-input_box {
    border: 1px solid rgba(149, 149, 161, 0.2);
    background-color: transparent;
    /* box-sizing: border-box; */
    border-radius: 5px;
    /* padding: 6px; */
    width: 100%;
    /* max-width: 30rem; */
    font-weight: 400;
    font-size: 16px;
    color: black;
    /* margin: 1rem; */
    /* padding: 10px; */
    display: grid;
}

.AIDVP1Ai-input_box input {
    margin: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}


/* //////////////////////////////////////// */
.AIDVP1chat-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    text-align: left;
}

.AIDVP1message-row {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-start;
}

.AIDVP1message-row.bot {
    flex-direction: row;
}

.AIDVP1message-row.user {
    flex-direction: row-reverse;
}

.AIDVP1avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px;
}

.AIDVP1message {
    background-color: white;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 55%;
}

.AIDVP1message p {
    margin: 0;
}

.AIDVP1message-row.bot .AIDVP1message {
    background-color: white;
}

.AIDVP1message-row.user .AIDVP1message {
    background-color: white;
}

@media only screen and (max-width: 800px) {
    .AIDVP1container {
        width: 88%;
        padding: 1rem;
    }
}