.pdf-container .image-div {
    margin-bottom: 1rem;
    max-width: 614px;
}

.pdf-container .image-div img {
    width: 100%;
}

.pdf-container .images-div {
    width: "100%";
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100% !important;
    padding-top: 1rem;
}

.pdf-container .pdf-header {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 2rem 0;
    position: relative;
    max-width: 610px;
    margin-left: auto;
    margin-right: auto;
}

.pdf-container .back-icon {
    position: absolute;
    top: 50%;
    left: -1.5rem;
    transform: translateY(-50%);
    color: var(--primary);
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer
}

@media only screen and (max-width: 1100px) {
    .pdf-container .back-icon {
        display: none;
    }
}

@media only screen and (max-width: 630px) {
    .pdf-container .images-div {
        padding: 0 1rem;
        width: calc(100% - 2rem);
    }

    .pdf-container .pdf-header {
        padding: 0 1rem;
    }
}