/* html,
body {
    height: 100%;
    margin: 0 auto;
} */

.LoginApp {
    text-align: center;
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #3184C9 -12.18%, #8f1b77 108.63%); */
    background-position: center;
    height: 100vh;
    font-family: Nunito_Sans;
    margin: 10px;
}

.LoginTemplate {
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px);
    height: 100vh;
    display: grid;
    align-items: center;
    align-content: center;
    z-index: -1;
}

.LoginAcceptBtn {
    padding: 0.8rem 2rem;
    background-color: black;
    color: white;
    border-radius: 6px;
    margin: 0.5rem auto;
    cursor: pointer;
    font-size: 0.7rem;
    font-weight: 600;
}

.LoginAcceptBtn:hover {
    background-color: rgb(53, 51, 51);

}

.form_modal {
    position: relative;
    width: 100%;
    height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form_modal .logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.form_modal .logo img {
    width: 100px;
    height: 100px;
    margin: 5px;
}

.form_modal .form-column {

    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

/* 
.form_modal__form {
    margin: 0;
    padding: 0;
} */

.form_modal__form_fields input {
    width: 100%;
    border: 1px solid #e6e6e6;
    padding: 10px;
    border-radius: 2px;
    background-color: #f0f0f0;
}

.form_modal__form_fields input:hover {
    border: 1px solid #8c8c8c;
}

.form_modal__form .remember-me .text {
    font-weight: normal;
    color: #4d4d4d;
    font-size: 13px;
}

.form_modal__form .forgot-password {
    font-weight: normal;
    color: #4d4d4d;
    font-size: 13px;
    text-decoration: underline;
}

.form_modal__submit_btn {
    background-color: #3498db;
    color: white;
    border-radius: 2px;
    border: 0;
}

.form_modal__submit_btn:hover {
    background-color: #2b88c6;
}

.form_modal__form .form_modal__btn-secondary {
    font-weight: 500;
    font-size: 14px;
}

.password_input_container .eye-icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-45%);
    opacity: 0.5;
}