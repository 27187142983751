.BST1App {
    text-align: center;
    background-color: #F4F9FC;
    font-family: "Mulish";
    margin-top: 8.5vh;
}

.BST1template {
    width: 70%;
    margin: auto;
    text-align: left;
}

.BST1template div {
    line-height: 30px;
}

.BST1content a {
    display: block;
    color: black;
}

.BST1content a:hover {
    text-decoration: underline;
}

.BST1section-1 {
    width: 100%;
    padding: 2rem 0rem;
    display: flex;
    justify-content: center;
}


.BST1section-1-image {
    width: 60%;
    height: 100%;
    object-fit: contain;
    border: 1px solid rgb(231, 231, 231);
}

.BST1tagsection a {
    color: black;
    border: 1px solid black;
    border-radius: 1.5rem;
    padding: 8px 15px;
    margin-right: 1rem;
}

@media only screen and (max-width: 800px) {

    .BST1App {
        margin-top: 7vh;
    }

    .BST1template {
        width: 90%;
    }

    .BST1section-1-image {
        width: 100%;
    }

}