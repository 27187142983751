.BCPO1App {
    text-align: center;
    background-color: #F4F9FC;
    font-family: "Mulish";
    margin-top: 8.5vh;
}

.BCPO1template {
    width: 70%;
    margin: auto;
    text-align: left;
}

.BCPO1template div {
    line-height: 30px;
}

.BCPO1section-1 {
    width: 100%;
    padding: 2rem 0rem;
    display: flex;
    justify-content: center;
}


.BCPO1section-1-image {
    width: 60%;
    height: 100%;
    object-fit: contain;
    border: 1px solid rgb(231, 231, 231);
}

.BCPO1tagsection a {
    color: black;
    border: 1px solid black;
    border-radius: 1.5rem;
    padding: 8px 15px;
    margin-right: 1rem;
}


.BCPO1section-2 {
    width: 100%;
    display: flex;
    justify-content: start;
}


.BCPO1section-2-image {
    width: 5rem;
    height: 5rem;
    object-fit: contain;
}

.BCPO1MainContainer {
    display: flex;
    flex-wrap: wrap;
}

.BCPO1Container {
    width: 42%;
    display: flex;
    flex-direction: column;
    font-size: 1.05rem;
    font-weight: 800;
    margin: 1rem;
}

@media only screen and (max-width: 800px) {

    .BCPO1App {
        margin-top: 7vh;
    }

    .BCPO1template {
        width: 90%;
    }

    .BCPO1section-1-image {
        width: 100%;
    }

    .BCPO1Container {
        width: 90%;
    }
}