.profile_wrapper {
    width: 60%;
    margin: auto !important;
}

.profile_wrapper_heading {
    /* font-weight: 400;
    font-size: 28px; */
    line-height: 38px;
    text-align: center;
}

.profile_container__field_container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
}

.profile_container__field_container p {
    font-weight: 600;
}

.profile_container__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile_container_left_field {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile_container_left_field img {
    border-radius: 10px;
    cursor: pointer;
    width: 7rem;
}

.field_value {
    font-size: 14px;
    line-height: 19px;
}


.profile_change_password {
    cursor: pointer;
    font-size: 14px;
    padding: 0.5rem;
    color: #3184C9;
}

.profile_change_password:hover {
    text-decoration: underline;
}

.profile_name_input {
    border: 1px solid rgba(17, 17, 19, 0.2);
    background-color: white;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 12px;
    width: 100%;
    transition: 0.2s;
    font-family: Nunito_Sans_Light;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: black;
}

.profile_edit_btn {
    background-color: #3184C9;
    border: 1px solid #F4F9FC;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    padding: 8px 12px;
    margin-left: 1rem;
    cursor: pointer;
}

.profile_edit_btn:hover {
    border: 1px solid #3184C9;
}

.profile_delete_account_btn {
    background-color: transparent;
    color: #FF0000;
    font-weight: 600;
    padding: 10px 12px;
    cursor: pointer;
}

.profile_delete_account_btn:hover {
    text-decoration: underline;
}


@media only screen and (max-width: 600px) {
    .profile_wrapper {
        width: 100%;
    }
}