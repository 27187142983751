.ADP1App {
    text-align: center;
    background-color: white;
    background-position: center;
    min-height: 100vh;
    margin-top: 8.5vh;
    font-family: Campton, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.ADP1template {
    display: grid;
    align-items: center;
    align-content: center;
    z-index: -1;
    color: black;
    min-height: 92vh;
}

.ADP1backBtn {
    cursor: pointer;
    color: black;
    text-align: left;
    padding: 2rem 0rem 0rem 2rem;
    display: flex;
    align-items: center;
    width: fit-content;
}

.ADP1container {
    width: 70%;
    margin: auto;
}

.ADP1templatetext {
    font-weight: 600;
    padding: 2.5rem 4vh 4vh;
    font-size: 0.875rem;
    letter-spacing: .1em;
}

.ADP1Button {
    padding: 0.875rem 1.75rem;
    background-color: #337ab7;
    color: white;
    border: 1px solid #337ab7;
    border-radius: 5px;
    margin: 5vh auto;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Mulish";
}

.ADP1Button:hover {
    background-color: #0E2C45;
    color: white;
    border: 1px solid #0E2C45;
}

.ADP1movingThumbnail {
    width: 75vw;
    height: 85vh;
    margin: auto;
    border: 10px solid rgb(220, 224, 222);
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.ADP1movingImg {
    height: 85vh;
    object-fit: cover;
}

.ADP1iframe {
    width: 88%;
    /* min-height: 88vh; */
    border: none;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* z-index: 1; */
}

.ADP1iframeMob {
    width: 25rem;
    /* min-height: 85vh; */
    border: none;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* z-index: 1; */
}

.ADP1mobileScreen {
    width: 8rem;
    background-color: white;
    height: 2rem;
    border-radius: 16px;
    position: absolute;
    margin: -16px;
}

.ADP1icon {
    background-color: rgb(243, 243, 241);
    border: 1px solid rrgb(243, 243, 241);
    border-radius: 5px;
    color: rgba(11, 35, 48, 0.5);
    padding: 8px 13px 18px 13px;
    margin: 2px;
    cursor: pointer;
    height: 22px;
    width: 25px;
    font-size: 1.3rem;
}

.ADP1icon:hover {
    background-color: rgba(186, 193, 196, 0.7);
    color: rgb(11, 35, 48);
}

.ADP1getStart {
    align-items: center;
    margin: 2vh auto;
    cursor: pointer;
}

.ADP1getStartBtn {
    align-items: center;
    background: white;
    border: 1px solid black;
    border-radius: 5px;
    color: black;
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    justify-content: center;
    padding: 0.8rem 2rem;
    font-family: "Mulish";
}

.ADP1getStartBtn:hover {
    background-color: #0E2C45;
    color: white;
}

.ADP1glow {
    position: absolute;
    width: 60%;
    height: 80%;
    opacity: 0.5;

}

.ADP1skeleton {
    width: 80%;
    height: 70vh;
    border-radius: 1rem;
    margin: auto;
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsla(201, 20%, 80%, 0.421);
    }

    100% {
        background-color: hsla(204, 20%, 95%, 0.204);
    }
}


@media only screen and (max-width: 1100px) {
    .ADP1backBtn {
        display: none;
    }
}

@media only screen and (max-width: 800px) {

    .ADP1App {
        margin-top: 5vh;
    }

    .ADP1container {
        width: 95%;
    }

    .ADP1movingThumbnail {
        height: 25vh;
    }

    .ADP1movingImg {
        height: 25vh;
    }

    .ADP1iframe {
        width: 90%;
    }

    .ADP1iframeMob {
        width: 90%;
    }
}
@media (min-width: 769px) {
    .ADP1iframe > div {
        flex-direction: row !important; /* Revert to row layout for larger screens */
    }
    .ADP1iframe > div > div:first-child {
        width: 20% !important; /* Revert to 20% width for the left bar */
        order: 0 !important; /* Revert to original order */
    }
    .ADP1iframe > div > div:last-child {
        width: 80% !important; /* Revert to 80% width for the right side */
        order: 0 !important; /* Revert to original order */
    }
}