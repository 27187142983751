.SM1modalMain {
    text-align: center;
    /* margin-top: 26vh; */
    border-radius: 2vh;
    /* border: 2px solid #C00DE4; */
}

.SM1shareTitle {
    margin: 2vh 0vh;
    line-height: 2rem;
    font-size: 1.5rem;
    font-weight: 600;
}

.SM1Continuebtn {
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    padding: 12px 20px;
    border-radius: 5px;
    margin: 2vh;
    color: black;
    cursor: pointer;
}

.SM1Continuebtn:hover {
    background-color: #0E2C45;
    border: 1px solid #0E2C45;
    color: white;
}

.SM1iconCircle {
    border: 1px solid black;
    border-radius: 50%;
    position: relative;
    padding: 5px;
    font-size: 3vh;
    margin: 6px;
    height: 6vh;
    width: 6vh;
    color: black;
    background: white;
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SM1iconCircle i {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.modal-backdrop {
    z-index: -1;
}

.SM1modalShare-btn {
    border: 1px solid #0E2C45;
    background: transparent;
    border-radius: 5px;
    font-size: 16px;
    color: #0E2C45;
    padding: 10px 15px;
    margin: 10px 0px 20px 0px;
    cursor: pointer;
    width: 10rem;
}

.SM1modalShare-btn:hover {
    color: white;
    background-color: #0E2C45;
}

.SM1modalShare-btn.selected {
    color: white;
    background: #0E2C45;
}

.SM1modalShare-icons {
    display: flex;
    justify-content: space-around;
    margin: 20px;
}

@media (max-width: 800px) {}