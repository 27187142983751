:root {
  --primary: #3184C9;
  --primary-v2: #3184C9;
  --primary-dark: rgba(11, 19, 80, 1);

  --color-secondary: rgb(223, 223, 223);
  --aqua-green: rgba(53, 249, 202, 1);
  --grey: rgba(219, 217, 217, 1);
  --white: rgba(251, 251, 247, 1);
  --hero-padding-x: 60px;
  --light-gray: #4747478a;
  /* android */
  --nav-height-mobile: 71px;
  /* z-indexes */
  --modal-index: 4000;
  --navbar-index: 2000;
  --menu-index: 6000;
  --home-page-mobile-breakpoint: 1100px;


  --bg-color: transparent;
  --que-bgColor: white;
  --que-fontColor: white;
  --que-fontSize: 16px;
  --que-fontFamily: Poppins;
  --que-fontWeight: normal;
  --que-textDecoration: none;
  --que-fontStyle: normal;
  --que-textAlign: unset;
  --que-textTransform: none;

  --desc-bgColor: white;
  --desc-fontColor: white;
  --desc-fontSize: 16px;
  --desc-fontFamily: Poppins;
  --desc-fontWeight: normal;
  --desc-textDecoration: none;
  --desc-fontStyle: normal;
  --desc-textAlign: unset;
  --desc-textTransform: none;

  --ans-bgColor: white;
  --ans-hover-bgColor: #3184C9;
  --ans-hover-fontColor: white;
  --ans-fontColor: black;
  --ans-fontSize: 16px;
  --ans-fontFamily: Poppins;
  --ans-fontWeight: normal;
  --ans-textDecoration: none;
  --ans-fontStyle: normal;
  --ans-textAlign: unset;
  --ans-textTransform: none;

  --btn-bgColor: white;
  --btn-hover-bgColor: #3184C9;
  --btn-hover-fontColor: white;
  --btn-fontColor: black;
  --btn-fontSize: 16px;
  --btn-fontFamily: Poppins;
  --btn-fontWeight: normal;
  --btn-textDecoration: none;
  --btn-fontStyle: normal;
  --btn-textAlign: unset;
  --btn-textTransform: none;

  --timer: 30;
}

.bottomFixedNav {
  padding: 4px;
  background: white;
  width: 100%;
  color: black;
  bottom: 0;
  position: fixed;
  text-align: center;
  z-index: 1;
}

.bottomFixedNavHeight {
  height: 6vh;
}

@media only screen and (max-width: 800px) {
  .bottomFixedNav {
    padding: 0;
  }

  .bottomFixedNavHeight {
    height: 6vh;
  }
}

.tourcss {
  /* background-color: #3184C9 !important;
  color: white !important; */
}

/* //////////////////// Element Animation //////////////////////// */
.animation-fade-in {
  animation: fade-in 1s ease 0s 1;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animation-fade-out {
  animation: fade-out 1s ease 0s 1;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animation-left-to-right {
  animation: left-to-right 1s ease 0s 1;
}

@keyframes left-to-right {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.animation-right-to-left {
  animation: right-to-left 1s ease 0s 1;
}

@keyframes right-to-left {
  0% {
    transform: translateX(200%);
  }

  100% {
    transform: translateX(0);
  }
}

.animation-top-to-bottom {
  animation: top-to-bottom 1s ease 0s 1;
}

@keyframes top-to-bottom {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.animation-bottom-to-top {
  animation: bottom-to-top 1s ease 0s 1;
}

@keyframes bottom-to-top {
  0% {
    transform: translateY(200%);
  }

  100% {
    transform: translateY(0);
  }
}

.animation-zoom-out {
  animation: zoom-out 1s ease 0s 1;
}

@keyframes zoom-out {
  0% {
    transform: scale(1.8, 1.8);
  }

  100% {
    transform: scale(1, 1);
  }
}

.animation-zoom-in {
  animation: zoom-in 1s ease 0s 1;
}

@keyframes zoom-in {
  0% {
    transform: scale(0.1, 0.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.animation-bounceInLeft {
  animation: bounceInLeft 2s ease 0s 1;
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  40% {
    opacity: 1;
    transform: translateX(0);
  }

  50% {
    transform: translateX(-30%);
  }

  70% {
    transform: translateX(0);
  }

  80% {
    transform: translateX(-20%);
  }

  100% {
    transform: translateX(0);
  }
}


.animation-bounceInRight {
  animation: bounceInRight 2s ease 0s 1;
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }

  40% {
    opacity: 1;
    transform: translateX(0);
  }

  50% {
    transform: translateX(30%);
  }

  70% {
    transform: translateX(0);
  }

  80% {
    transform: translateX(20%);
  }

  100% {
    transform: translateX(0);
  }
}

.animation-swirl {
  animation: swirl 1.2s cubic-bezier(.6, 0, .4, 1);
}

@keyframes swirl {
  0% {
    transform: scale(0) rotate(0deg);
  }

  100% {
    transform: scale(1) rotate(1turn);
  }
}

.animation-rotate {
  animation: rotate 1.2s cubic-bezier(.6, 0, .4, 1);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1turn);
  }
}

.animation-rollInLeft {
  animation: rollInLeft 1.2s cubic-bezier(.6, 0, .4, 1);
}

@keyframes rollInLeft {
  0% {
    transform: translateX(-100%) rotate(0deg);
  }

  100% {
    transform: translateX(0) rotate(1turn);
  }
}

.animation-rollInRight {
  animation: rollInRight 1.2s cubic-bezier(.6, 0, .4, 1);
}

@keyframes rollInRight {
  0% {
    transform: translateX(200%) rotate(0deg);
  }

  100% {
    transform: translateX(0) rotate(-1turn);
  }
}

/* ///////////////////// Slide Animation ///////////////////////////// */
.slide-animation-rotateCarouselLeftIn {
  animation: sa-rotateCarouselLeftIn 1s ease both;
}

@keyframes sa-rotateCarouselLeftIn {
  0% {
    transform: translate3d(200%, 0, 0) scale(.4) rotateY(65deg)
  }
}

.slide-animation-moveFromRight {
  animation: sa-moveFromRight 1.2s cubic-bezier(.4, 0, .2, 1) both;
}

@keyframes sa-moveFromRight {
  0% {
    transform: translate3d(100%, 0, 0);
  }
}

.slide-animation-right-reveal {
  animation: sa-right-reveal 1.2s cubic-bezier(.2, 0, .3, 1)
}

@keyframes sa-right-reveal {
  0% {
    clip-path: inset(0 100% 0 0);
  }

  100% {
    clip-path: inset(0 0 0 0);
  }
}

.slide-animation-rotateCubeLeftIn {
  animation: sa-rotateCubeLeftIn 1.2s cubic-bezier(.6, 0, 1, 1) both;
  transform-origin: 0 50%;
}

@keyframes sa-rotateCubeLeftIn {
  0% {
    transform: translate3d(100%, 0, 0) rotateY(90deg);
    z-index: 1;
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .4, 1);
    transform: translate3d(50%, 0, 0) scale(.8) rotateY(45deg);
    z-index: 999;
  }

  100% {
    z-index: 999;
  }
}

.slide-animation-rotateRoomLeftIn {
  animation: sa-rotateRoomLeftIn 1.2s cubic-bezier(.5, 0, .2, 1) both;
  transform-origin: 0 50%;
}

@keyframes sa-rotateRoomLeftIn {
  0% {
    transform: translate3d(100%, 0, 0) rotateY(-90deg);
  }
}

.slide-animation-scaleUp {
  animation: sa-scaleUp 1.2s cubic-bezier(.4, 0, .4, 1) both;
}

@keyframes sa-scaleUp {
  0% {
    opacity: 0;
    transform: scale(.8);
  }
}

.slide-animation-rotateSlideIn {
  animation: sa-rotateSlideIn 1.2s cubic-bezier(.4, 0, .4, 1) both;
}

@keyframes sa-rotateSlideIn {
  0% {
    transform: translate3d(200%, 0, 0);
    scale: 0.6;
  }

  25% {
    transform: translate3d(200%, 0, 0);
    scale: 0.6;
  }

  75% {
    scale: 0.6;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.slide-animation-PopIn {
  animation: sa-PopIn 1.2s cubic-bezier(.5, 0, .5, 1) both;
  transform-origin: 50% 50%;
}

@keyframes sa-PopIn {
  0% {
    opacity: 0;
    transform: scale(.6);
  }

  25% {
    opacity: 1;
    transform: scale(1.08);
  }

  45% {
    transform: scale(.97);
  }

  60% {
    transform: scale(1.03);
  }

  75% {
    transform: scale(.99);
  }

  85% {
    transform: scale(1.01);
  }

  100% {
    transform: scale(1);
  }
}

/* //////////////////////////////////////////////// */
.hideScrollbar::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}

body {
  /* height: -webkit-fill-available; */
}

body,
input,
textarea {
  font-family: "Mulish";
}

a {
  text-decoration: none;
  color: initial;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

button {
  border: 0;
}

/* reset styles */
ul {
  list-style: none;
}

input {
  border: 0;
  outline: 0;
}

textarea {
  resize: none;
}

.modal-backdrop.show {
  display: none;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(240, 237, 237);
  border-radius: 10px;
  /* outline: 1px solid slategrey; */
}


@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@font-face {
  font-family: Nunito_Sans;
  src: url(./assets/fonts/NunitoSans-Regular.ttf);
}

@font-face {
  font-family: Nunito_Sans_ExtraLight;
  src: url(./assets/fonts/NunitoSans-ExtraLight.ttf);
}

@font-face {
  font-family: Nunito_Sans_Semibold;
  src: url(./assets/fonts/NunitoSans-SemiBold.ttf);
}

@font-face {
  font-family: Nunito_Sans_Bold;
  src: url(./assets/fonts/NunitoSans-Bold.ttf);
}

@font-face {
  font-family: Nunito_Sans_Light;
  src: url(./assets/fonts/NunitoSans-Light.ttf);
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter/Inter-Regular.ttf);
}

@font-face {
  font-family: Inter_Semibold;
  src: url(./assets/fonts/Inter/Inter-Medium.ttf);
}

@font-face {
  font-family: Inter_Bold;
  src: url(./assets/fonts/Inter/Inter-SemiBold.ttf);
}

/* global styles */

.page-container {
  min-height: calc(100vh - 64px);

  margin: 0;
  margin-top: 58px;
}

.btn-primary,
.btn-secondary {
  border-radius: 0px;
  padding: 10px 16px;
  font-size: 14px;

  cursor: pointer;
}

.btn-primary {
  background-color: #0E2C45;
  border: 1px solid #0E2C45;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  border-radius: 5px;
}

.btn-primary:hover {
  background-color: white;
  color: black;
}

.btn-secondary {
  background-color: transparent;
  border: 1px solid black;
  color: #000;
  cursor: pointer;
  font-weight: 700;
  border-radius: 5px;
}

.btn-secondary:hover {
  background-color: #0E2C45;
  color: white;
}

.color-light-gray {
  color: var(--light-gray);
}

@media only screen and (max-width: 1100px) {
  .btn-primary:active {
    background-color: black;
  }

  .btn-secondary:active {
    background-color: rgba(219, 217, 217, 1);
  }
}

/* classes */

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-4 {
  margin-bottom: 24px;
}

.mr-3 {
  margin-right: 12px;
}

.mr-4 {
  margin-right: 15.5px;
}

.pl-10 {
  padding-left: 38px;
}

.center-y {
  display: flex;
  align-items: center;
}

.center-x {
  display: flex;
  justify-content: center;
}

.center-xy {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-width {
  width: 100%;
}

/* modal */
.modal_container {
  position: fixed;
  z-index: var(--modal-index);
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  background-color: rgba(28, 39, 48, .5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.modal_basic {
  position: relative;
  padding: 46px 92px;
  background-color: #fff;
  border-radius: 12px;
  max-width: calc(560px - 184px);
}

.modal_header {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: black;
  text-align: left;
  margin-bottom: 14px;
}

.modal_text {
  color: #474747;
  margin-bottom: 14px;
}

.modal_submit_btn {
  padding: 13px 20px;
  font-family: Nunito_Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.modal_error_msg {
  text-align: center;
  color: #e53636;
}

.modal_success_msg {
  text-align: center;
  color: #3abe20;
}

.modal_container .resend-code-btn {
  display: inline;
  color: #8f1b77;
  margin-left: 1rem;
  text-decoration: underline;
  cursor: pointer;
  margin-left: auto;
}

.modal_container .resend-code-btn#disabled {
  opacity: 0.4;
  pointer-events: none;
}

.modal_container-enter-code .modal_basic {
  padding-bottom: 24px;
}

/* close button */
.modal_container .close-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal_container .modal_back_btn {
  display: inline-block;
  top: 50%;
  color: black;
  cursor: pointer;
  margin-right: 13px;
}

/* 
.modal_container .modal_back_btn:hover {
  background-color: #ebebeb8a;
  border-radius: 50%;
} */

@media only screen and (max-width: 1100px) {

  /* close button */
  .modal_container .close-icon {
    display: none;
  }

  .modal_container .modal_basic {
    align-self: stretch;
    max-width: 100%;
    width: 100%;
    padding: 40px 20px !important;
  }

  .modal_header {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {

  .btn-primary,
  .btn-secondary {
    padding: 10px 14px;
    font-size: 13px;
  }
}

/* ANDROID MENUS */
.menu_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  z-index: var(--menu-index);
  display: flex;
  flex-direction: column;
}

/* .menu_container .menu_wrapper  */
.menu_header {
  background: #3184C9;
  padding: 16px 20px;
  padding-top: 35px;
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
}

.menu_header .menu_header__title {
  font-family: "Mulish";
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
}

/* .navbar-container .menu_header {
  justify-content: flex-end;
} */

.menu_header .close_menu_btn {
  position: relative;
  width: 8.57px;
  height: 8.57px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu_header .close_menu_btn svg {
  width: 8.57px;
  height: 8.57px;
}

.menu_header .close_menu_btn:active:before {
  width: 35px;
  height: 35px;
}

.menu_header .close_menu_btn:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: rgb(223, 223, 223, 0.2);
  transition: 0.2s;
}

.icon-button {
  position: relative;
  z-index: 10;
}

.icon-button:active:before {
  width: 40px;
  height: 40px;
}

.icon-button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: rgba(223, 223, 223, 0.2);
  transition: 0.2s;
  z-index: -1;
}

.close-icon.icon-button {
  width: 40px;
  height: 40px;
}

.close-icon.icon-button img {
  width: 16px;
  height: 16px;
}

.close-icon.icon-button:hover {
  background-color: #ebebeb62;
  border-radius: 50%;
}

.close-icon.icon-button:before {
  background-color: #d6d6d673;
}

.modal_back_btn.icon-button:before {
  background-color: #d6d6d673;
}